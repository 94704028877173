let proceeding = false;
let bridgeQueue = [];

// Returns a promise which will be executed one after another in `proceed` fn
export const executeInQueue = (fn, args = []) => {
  let res = new Promise((resolve, reject) => {
    bridgeQueue.push({resolve, reject, fn, args});
  })
  if (!proceeding){
    proceeding = true;
    proceed();
  }
  return res;
};

const proceed = async () => {
  if (!bridgeQueue.length) throw Error('Can\'t proceed on empty array of tasks');
  let { resolve, reject, fn, args} = bridgeQueue[0];
  try {
    resolve(await fn(...args));
  } catch (err) {
    reject(err);
  } finally {
    bridgeQueue.shift();
  }
  if (bridgeQueue.length) {
    proceed();
  } else {
    proceeding = false;
  }
};


// bridgeQueue.executeInQueue = (fn, args) => {
//   let res = new Promise((resolve, reject) => {
//     bridgeQueue.push({resolve, reject});
//   }).then(() => {
//     return fn(...args); 
//   }).catch((err) => {
//     throw new Error(err);
//   })
//   if (!proceeding){
//     proceeding = true;
//     proceed();
//   }
//   return res;
// }
// bridgeQueue.executeInQueue.bind(bridgeQueue);
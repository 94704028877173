export default {
  mounted(){
    // this.$store.dispatch('layout/setThemeColor', this.bgColor);
    this.$nextTick(() => {
      let headerHeight = this.$el.offsetHeight
      this.$store.dispatch('layout/setHeaderHeight', headerHeight)
    })
  },
  updated(){
    this.$nextTick(() => {
      let headerHeight = this.$el.offsetHeight
      this.$store.dispatch('layout/setHeaderHeight', headerHeight)
    })
  },
  destroyed(){
    this.$nextTick(() => {
      this.$store.dispatch('layout/setHeaderHeight', 0)
    })
  }
}
import Vue from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import vuetify from './plugins/vuetify';
import { VueMaskDirective } from "v-mask";
import VueApexCharts from 'vue-apexcharts';
import VueBarcode from '@chenfengyuan/vue-barcode';
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import baseApi from "@/helpers/baseApi";
import { translatePlugin } from "@/plugins/i18n.js";
// import LetItSnow from 'vue-let-it-snow'; //till new-year
// import "@egjs/vue-flicking/dist/flicking.css";
// import "@egjs/flicking-plugins/dist/pagination.css";
// Vue.use(LetItSnow);
Vue.use(VueApexCharts);
Vue.use(translatePlugin);
Vue.component('apexchart', VueApexCharts);
Vue.component(VueBarcode.name, VueBarcode);
Vue.directive('mask', VueMaskDirective);
Vue.config.productionTip = false
window.api = baseApi
if (process.env.NODE_ENV === 'production'){
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    environment: process.env.VUE_APP_SENTRY_ENV,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        // tracingOrigins: [
        //   process.env.VUE_APP_API_HOST
        // ],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    trackComponents: true,
  });
}

new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')

<template>
  <div 
    @click.self="$emit('close')"
    class="bottom-drawer"
  >
    <div 
      class="bottom-drawer__body"
      :style="{
        height,
        maxHeight,
        minHeight,
      }"
      :class="{
        'bottom-drawer__body--gray': isBgGray
      }"
    >
      <div 
        v-if="notch"
        class="bottom-drawer__body__notch"
      />
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReusableBottomDrawer',
  props: {
    title: String,
    isBgGray: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      validator: val => CSS.supports('height', val),
    },
    maxHeight: {
      type: String,
      validator: val => CSS.supports('max-height', val),
    },
    minHeight: {
      type: String,
      validator: val => CSS.supports('min-height', val),
    },
    notch: {
      type: Boolean,
      default: false,
    },
  },
  
  mounted(){
    this.$root.$el.style.overflow = 'hidden'; 
  },
  
  beforeDestroy(){
    this.$root.$el.style.overflow = null;
  },

}
</script>
<style lang="scss">
.bottom-drawer{
  position: fixed;
  overflow-y: hidden;
  top: 0; 
  right: 0;
  bottom: 0;
  left: 0;
  background: #151c2266;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  &__body{
    background: #FFF;
    border-radius: 24px 24px 0px 0px;
    &__notch {
      margin: 6px auto 12px; 
      width: 32px;
      height: 4px;
      background: #8E8E93;
      border-radius: 3px;
    }
  }
  &__body--gray{
    background: #F6F6F6;;
  }
}
</style>
<template>
  <div class="onboarding__overlay">
    <div 
      class="onboarding__card"
      v-touch="{
        left: () => handleNextClick(true),
        right: () => handleBackClick(),
      }"
    >
      <div 
        class="onboarding__card__top"
        :class="{
          'onboarding__card__top--align-top': slideImageAlignsTop(slides[currentStep]),
          'onboarding__card__top--video': slides[currentStep].youtubeId,
        }"
      >
        <!-- <div class="card__top__image-container"> -->
          <v-img
            v-if="slides[currentStep].image"
            :key="currentStep"
            class="card__top__image"
            content-class="card__top__image__content"
            :src="slides[currentStep].image.src"
            :height="slides[currentStep].image.height"
            :width="slides[currentStep].image.width"
            :transition="'scroll-x-transition'"
            @load="handleImageLoad"
            contain
          >
            <div 
              v-if="[2,3,4].includes(currentStep) && imageLoaded"
              class="image__content__arrow"
              :class="'image__content__arrow--step-'+currentStep"
            >
              <svg width="49" height="82" viewBox="0 0 49 82" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M.293 7.293a1 1 0 000 1.414l6.364 6.364a1 1 0 001.414-1.414L2.414 8l5.657-5.657A1 1 0 006.657.93L.293 7.293zM49 81.5c0-12.217.011-30.781-5.941-46.296-2.985-7.778-7.487-14.845-14.286-19.964C21.965 10.114 12.933 7 1 7v2c11.567 0 20.16 3.011 26.57 7.838 6.42 4.834 10.73 11.548 13.621 19.083C46.99 51.03 47 69.217 47 81.5h2z" fill="#EF7F1A"/></svg>
            </div>
            <div 
              v-if="[2,4].includes(currentStep) && imageLoaded"
              class="image__content__circle"
              :class="'image__content__circle--step-'+currentStep"
            >
              <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><circle cx="14" cy="14" r="13.5" stroke="#EF7F1A"/></svg>
            </div>
          </v-img>
          <v-responsive
            v-else-if="slides[currentStep].youtubeId"
            :aspect-ratio="16/9"
            content-class="d-flex justify-center"
          >
            <iframe
              :src="'https://www.youtube.com/embed/'+slides[currentStep].youtubeId"
              width="100%"
              frameborder="0"
              style="
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
              "
              allowfullscreen
            ></iframe>
          </v-responsive>
      </div>
      <div class="onboarding__card__body">
        <div class="onboarding__card__body__text-container">
          <p class="large-medium">{{ slides[currentStep].title }}</p>
          <p class="small">{{ slides[currentStep].content }}</p>
        </div>
        
        <div 
          class="card__body__btn"
          @click="handleNextClick(false)"
        >
          {{ 
            (currentStep === slides.length-1) 
              ? $translate('onboarding_action_close') 
              : $translate('onboarding_action_next')
          }}
        </div>
        <div class="card__body__steps-container">
          <div 
            class="card__body__steps-container__back-btn"
            :class="{
              'card__body__steps-container__back-btn--disabled': currentStep === 0,
            }"
            @click="handleBackClick"  
          >
            <img 
              :src="require('@/assets/svg/onboarding_back.svg')" 
              alt=""
            >
          </div>
          <div 
            v-for="(step, key) in slides"
            :key="key"
            class="card__body__steps-container__step"
            :class="{
              'card__body__steps-container__step--active': key === currentStep
            }"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppOnboarding',
  props: ['value'],
  data(){
    return {
      currentStep: 0,
      imageLoaded: false,
      slides: [
        {
          youtubeId: 'pWFZYwwMAhQ',
          title: this.$translate('onboarding_slide-1_title'),
          content: this.$translate('onboarding_slide-1_content')
        },
        {
          image: {
            src: require('@/assets/onboarding/onboarding_2.png'),
            width: 217,
            height: 180,
            alignTop: true,
          },
          title: this.$translate('onboarding_slide-2_title'),
          content: this.$translate('onboarding_slide-2_content')
        },
        {
          image: {
            src: require('@/assets/onboarding/onboarding_3.png'),
            width: 217,
            height: 180,
          },
          title: this.$translate('onboarding_slide-3_title'),
          content: this.$translate('onboarding_slide-3_content'),
        },
        {
          image: {
            src: require('@/assets/onboarding/onboarding_4.png'),
            width: 217,
            height: 180,
          },
          title: this.$translate('onboarding_slide-4_title'),
          content: this.$translate('onboarding_slide-4_content'),
        },
        {
          image: {
            src: require('@/assets/onboarding/onboarding_5.png'),
            width: 217,
            height: 180,
          },
          title: this.$translate('onboarding_slide-5_title'),
          content: this.$translate('onboarding_slide-5_content'),
        },
      ]
    }
  },
  methods: {
    
    slideImageAlignsTop(slide){
      return !!(slide?.image?.alignTop || slide?.youtubeId)
    },

    handleBackClick(){
      if (this.currentStep > 0){
        this.imageLoaded = false;
        this.currentStep--;
      }
    },

    handleNextClick(isSwipeAction){
      if (this.currentStep < this.slides.length-1){
        this.currentStep++ 
        this.imageLoaded = false;
      } else if (!isSwipeAction){
        this.$emit('input', false)
      }
    },

    handleImageLoad(){
      this.$nextTick(() => {
        this.imageLoaded = true;
      })
    }
  },
  
  mounted(){
    this.$root.$el.style.overflow = 'hidden';
  },
  
  beforeDestroy(){
    this.$root.$el.style.overflow = null;
  },
  
}
</script>
<style lang="scss">
.onboarding__overlay{
  position: fixed;
  overflow-y: auto;
  
  background-color: rgba(21, 28, 34, 0.4); 
  top:0; 
  left: 0; 
  right:0; 
  bottom: 0; 
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  .onboarding__card{
    background: #FFFFFF;
    margin: 24px;
    border-radius: 12px;
    overflow: hidden;
    max-width: 328px;
    .onboarding__card__top{
      background: #F6F6F6;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      min-height: 210px;
      padding: 5px 0 0;
      &--align-top{
        align-items: flex-start;
      }
      &--video{
        min-height: auto;
        padding: 0;
      }
      .card__top__image{
        margin: 0 auto;
        overflow: initial;
        .card__top__image__content{
          .image__content__arrow{
            position: absolute;
            animation-name: flash;
            animation-duration: 1s;
            animation-timing-function: ease-in-out;
            &--step-2{
              right: 38px;
              top: 40px;
            }
            &--step-3{
              right: 17px;
              top: 75px;
            }
            &--step-4{
              right: 14px;
              top: 40px;
            }
          }
          .image__content__circle{
            position: absolute;
            animation-name: flash;
            animation-duration: 1s;
            animation-timing-function: ease-in-out;
            &--step-2{
              right: 90px;
              top: 30px;
            }
            &--step-4{
              right: 65px;
              top: 30px;
            }
          }
        }
      }
    }
    .onboarding__card__body{
      padding: 24px;
      .onboarding__card__body__text-container{
        min-height: 140px;
        .large-medium{
          margin-bottom: 8px;
        }
        .small{
          color: #667A8B;
        }
      }
      .card__body__btn{
        background: #EF7F1A;
        border-radius: 12px;
        text-align: center;
        color: #FFFFFF;
        padding: 8px;
        margin-top: 24px;
        user-select: none;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.02em;
      }
      .card__body__steps-container{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 24px;
        height: 24px;
        position: relative;
        user-select: none;
        .card__body__steps-container__back-btn{
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          color: #151C22;
          .card__body__steps-container__back-btn--disabled{
            color: #DDE1E5;
          }
        }
        .card__body__steps-container__step{
          background: #DDE1E5;
          width: 6px;
          height: 6px;
          margin: 0 4px;
          border-radius: 50%;
          &.card__body__steps-container__step--active{
            background: #EF7F1A;
            width: 8px;
            height: 8px;
          }
        }
      }
    }
  }
  
}
@keyframes flash {
  from,
  50%,
  to {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }
}
</style>
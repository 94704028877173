import bridge from "@btsd/aitu-bridge";
import { translateLang } from "@/plugins/i18n.js";

const state = () => ({
  app_error: '',
  back_link_trigger_value: undefined,
  footer_is_shown: false,
  header_action: undefined,
  header_height: 0,
  header_title: '',
  is_select_auth_form: false,
  language: window.localStorage.getItem("language") || "ru",
  my_calendar_modal: false,
  rotated_chart_component: undefined,
  theme_color: 'default',
  show_burger_content: false,
})

const mutations = {
  'set_language'(state, payload){
    window.localStorage.setItem("language", payload);
    state.language = payload;
  },
  'set_rotated_chart_component'(state, payload){
    state.rotated_chart_component = payload;
  },
  'set_show_burger_content'(state, payload){
    state.show_burger_content = payload;
  },
  'set_my_calendar_modal'(state, payload){
    state.my_calendar_modal = payload;
  },
  'set_header_title'(state, payload){
    state.header_title = payload;
  },
  'set_header_action'(state, payload){
    state.header_action = payload;
  },
  'set_back_link_trigger_value'(state, payload){
    state.back_link_trigger_value = payload;
  },
  'set_app_error'(state, payload){
    state.app_error = payload;
  },
  'set_show_footer'(state, payload){
    state.footer_is_shown = !!payload;
  },
  'set_is_select_auth_form'(state, payload){
    state.is_select_auth_form = payload;
  },
  set_theme_color(state, color){
    state.theme_color = color || '#0086E7';
  },
  set_header_height(state, height){
    state.header_height = height;
  },
}

const actions = {
  setLanguage({ commit }, value){
    commit('set_language', value);
  },
  setRotatedChartComponent({ commit }, value){
    commit('set_rotated_chart_component', value)
  },
  clearRotatedChartComponent({ commit }){
    commit('set_rotated_chart_component', undefined)
  },
  showMyCalendarModal({ commit }){
    commit('set_my_calendar_modal', true);
  },
  hideMyCalendarModal({ commit }){
    commit('set_my_calendar_modal', false);
  },
  showFooter({ commit }){
    commit('set_show_footer', true);
  },
  hideFooter({ commit }){
    commit('set_show_footer', false);
  },
  alert({ commit }, value){
    if (typeof value === Error) {
      console.error(value)
    }
    commit('set_app_error', value);
  },
  setHeaderTitle({ commit }, value){
    commit('set_header_title', value)
  },
  clearHeaderTitle({ commit }){
    commit('set_header_title', "")
  },
  setHeaderAction({ commit }, value){
    commit('set_header_action', value)
  },
  clearHeaderAction({ commit }){
    commit('set_header_action', undefined)
  },
  setBackLinkTriggerValue({ commit, state }){
    commit('set_back_link_trigger_value', state.header_action);
  },
  setIsSelectAuthForm({commit}, val){
    commit('set_is_select_auth_form', val);
  },
  setThemeColor({ commit }, color){
    commit('set_theme_color', color)
  },
  setHeaderHeight({commit}, height){
    commit('set_header_height', height)
  },
  showBurgerContent({ commit, state }){
    let title = translateLang('title_menu', state.language);
    bridge.setTitle(title);
    bridge.setCustomBackArrowVisible(true);
    commit('set_show_burger_content', true);
  },
  hideBurgerContent({ commit, state}){
    bridge.setTitle(state.header_title);
    commit('set_show_burger_content', false);
  },
  toggleBurgerContent({ dispatch, state}){
    if (state.show_burger_content){
      dispatch('hideBurgerContent');
    } else {
      dispatch('showBurgerContent');
    }
  },
}

const getters = {
  'show_burger_content': (state) => {
    return state.show_burger_content;
  }
}

export const layout = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
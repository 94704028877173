import axios from "axios";

const baseApiInstance = axios.create({
  baseURL: (process.env.VUE_APP_API_HOST || ''),
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Platform': 'mobile',
  },
  params: {
    format: 'json'
  }
});

export default baseApiInstance;
export { baseApiInstance as baseApi };
const state = () => ({
  showReplyInputs: false,
  reply_id: undefined,
  reply_name: undefined,
  comments: [],
});

const mutations = {
  'set_show_reply_input': (state, payload) => {
    state.showReplyInputs = payload
  },
  'set_reply_user': (state, payload) => {
    state.reply_id = payload.id;
    state.reply_name = payload.name;
  },
  'set_comments': (state, payload) => {
    state.comments = payload;
  }
  // 'set_reply_model': (state, model, model_id) => {
  //   state.reply_model = model;
  //   state.reply_model_id = model_id;
  // },
}

const actions = {
  
  showReplyInputs({ commit }){
    commit('set_show_reply_input', true);
  },
  
  hideReplyInputs({ commit }){
    commit('set_show_reply_input', false);
  },

  setReplyUser({ commit }, {id, name}){
    commit('set_reply_user', {id, name})
  },
  
  clearReplyUser({ commit }){
    commit('set_reply_user', {
      id: undefined,
      name: undefined,
    })
  },
  
  setComments({ commit }, comments){
    commit('set_comments', comments);
  },

  addComment({ commit, state }, comment){
    commit('set_comments', [
      ...state.comments,
      comment,
    ])
  },
  
  clearComments({ commit, }){
    commit('set_comments', [])
  }
}

export const way = {
  namespaced: true,
  state,
  mutations,
  actions,
}
import baseApi from "@/helpers/baseApi";
import bridge from "@/helpers/bridge";
import { translateLang } from "@/plugins/i18n.js";

// mutation types 
const set_enabled = '🔔 Enabled'
const set_disabled = '🔔 Disabled'
const set_requested = '🔔 Requested: true'
const set_items = '🔔 Items Updated'
const set_item_by_index = '🔔 Item(id) Updated'
const set_viewed_all = '🔔 Viewed All';

const state = () => ({
  // 'enabled', 'disabled' or undefined
  requestLoading: false,
  status: window.localStorage.getItem('n'),
  requested: false,
  items: [],
  sendsApiRequest: true,
})

const mutations = {

  [set_enabled](state){
    state.status = "enabled";
  },
  
  [set_disabled](state){
    state.status = "disabled";
  },

  [set_requested](state){
    state.requested = true;
  },

  [set_items](state, payload){
    state.items = payload 
  },
  
  [set_item_by_index](state, { index, value }){
    state.items = state.items.map((oldVal, key) => (key === index ? value : oldVal));
    // state.items[index] = value;
  },

  [set_viewed_all](state){
    state.items = state.items.map((item) => ({ ...item, viewed: true }))
  },

}

const actions = {
  
  get({ commit }){
    return baseApi('api/notification/notification/').then((res) => {
      if (res?.data){
        commit(set_items, res.data);
      } else {
        throw Error('no notification items in response')
      }
    }).finally(() => {
      commit(set_requested)
    })
  }, 

  async setEnabled({ commit, dispatch, state, rootState }){
    state.requestLoading = true;
    try {
      if (bridge.detectedBridgePlatform){
        await bridge.enableNotifications();
      } else {
        throw(translateLang('store_notifications_no-aitu', rootState.layout.language))
      }
      let sendsApiRequest = !rootState.layout.is_select_auth_form;
      if (sendsApiRequest){
        await baseApi.post('/api/notification/enable_push/').catch(() => {});
      }
      window.localStorage.setItem('n', 'enabled');
      commit(set_enabled);
    } catch(err) {
      dispatch('layout/alert', err, { root: true});
    }
    state.requestLoading = false;
  },

  async setDisabled({ commit, dispatch, state, rootState }){
    state.requestLoading = true;
    try {
      let sendsApiRequest = !rootState.layout.is_select_auth_form;
      if (sendsApiRequest){
        await baseApi.post('/api/notification/disable_push/').catch(() => {});
      }
      window.localStorage.setItem('n', 'disabled');
      commit(set_disabled);
    } catch(err) {
      dispatch('layout/alert', err, { root: true });
    }
    state.requestLoading = false;
  },

  async switchStatus({ dispatch, state }){
    if (state.status === 'enabled'){
      await dispatch('setDisabled');
    } else {
      await dispatch('setEnabled');
    }
  },

  updateItem({state, commit}, value){
    let index = state.items.findIndex((item) => item.id === value.id);
    if (index >= 0){
      commit(set_item_by_index, { index, value });
    }
  },

  setItemViewedById({state, commit}, id){
    let index = state.items.findIndex((item) => item.id === id);
    let value = state.items.find((item) => item.id === id);
    if (index >= 0){
      commit(set_item_by_index, { index, value: { ...value, viewed: true } });
    }
  },

  viewAll({ commit }){
    commit(set_viewed_all);
  }
}

const getters = {
  // NOTIFICATION
  'item_by_id': (state) => (notification_id) => (
    state.items.find((item) => (item.id === notification_id))
  ),

  'count_by_menu_item_id': (state) => (menu_item_id) => (
    state.items.filter(notification => notification.category === menu_item_id).length
  ),

  'unread_count_by_menu_item_id': (state) => (menu_item_id) => (
    state.items.filter(notification => (
      notification.category === menu_item_id
      && !notification.viewed
    )).length
  ),

  'unread_total': (state) => (
    state.items.filter(notification => !notification.viewed).length
  ),

  'by_menu_item_id': (state) => (menu_item_id) => (
    state.items.filter(notification => notification.category === menu_item_id)
  ),

  // 'by_menu_item_id_sorted_by_date': (getters) => (menu_item_id) => (
  //   getters
  // ),
  'maped_by_date': (state) => {
    try{
      let items = state.items;
      return items.reduce((acc, cur) => {
        let date = new Date(Date.parse(cur.created_at.replace(/ /g,"T")));
        let dateStr = date.toLocaleDateString('ru-RU', {
          weekday: 'long',
          month: "short",
          day: 'numeric',
        });
        let key = acc.findIndex((el) => el?.title === dateStr); 
        if (key >= 0){
          acc[key] = {
            ...acc[key],
            items: [
              ...acc[key]['items'],
              cur,
            ]
          }
        } else {
          acc[acc.length] = {
            title: dateStr,
            items: [ cur ]
          }
        }
        return acc;
      }, [])
    } catch {
      return false;
    }
  }
  
}

export const notifications = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
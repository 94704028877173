
import { generateWatermarkImageUri } from "@/helpers/utils";
import bridge from "@/helpers/bridge";


const state = () => ({
  mainscreen: { ...initialMainscreen },
  fullscreen: { ...initialFullscreen },
  halfscreen: { ...initialHalfscreen },
  isSubmitting: false,
  noScreenshot: false,
})

const mutations = {
  'set_is_submitting'(state, value){
    state.isSubmitting = value
  },
  'set_section'(state, { section, ...rest }){
    state[section] = {
      ...rest
    }
  },
  'set_section_content'(state, { section, value }){
    state[section].content = value;
  },
  'set_section_errors'(state, { section, errors }){
    state[section].errors = errors;
  },
  'set_section_footer_content'(state, { section, value }){
    state[section].footer_content = value;
  },
  'clear_section_errors'(state, section){
    state[section].errors = {}
  },
  'set_section_field_value'(state, { section, field, value }){
    state[section].values = {
      ...state[section].values,
      [field]: value,
    }
  },
  'set_no_screenshot'(state, value){
    state.noScreenshot = value;
  }
}

const actions = {

  setFieldValue({state, commit}, { field, value }){
    if (state.fullscreen.content){
      commit('set_section_field_value', { section: 'fullscreen', field, value });
    } else {
      commit('set_section_field_value', { section: 'mainscreen', field, value });
    }
  },

  setMainscreenForm({ commit, dispatch }, form){
    commit('set_section', {
      section: 'mainscreen',
      content: form.content,
      errors: {},
      footer_content: form.footer_content,
      footer_color: form.footer_color,
      is_centered: form?.options?.centered,
      no_screenshot: form?.options?.no_screenshot,
      validations: getContentValidations(form.content),
      values: {},
      values_from_storage: form?.options?.storage_get,
      watermark_image: form?.options?.watermark 
        ? generateWatermarkImageUri(form?.options?.watermark_text)
        : undefined,
    });
    dispatch(form?.options?.no_screenshot ? 'disableScreenshot' : 'enableScreenshot');
  },
  
  setMainscreenErrors({ commit }, errors){
    commit('set_section_errors', {
      section: 'mainscreen',
      errors
    })
  },
    
  setFullscreenForm({ commit, dispatch }, form){
    commit('set_section', {
      section: 'fullscreen',
      content: form?.content,
      errors: {},
      footer_content: form?.footer_content,
      footer_color: form?.footer_color,
      has_header_x_close: form?.options?.fullscreen_close,
      is_centered: form?.options?.centered,
      no_screenshot: form?.options?.no_screenshot,
      validations: getContentValidations(form.content || []),
      values: {},
      values_from_storage: form?.options?.storage_get,
      watermark: form?.options?.watermark && form?.options?.watermark_text || undefined,
    });
    dispatch(form?.options?.no_screenshot ? 'disableScreenshot' : 'enableScreenshot');
  },

  setFullscreenErrors({ commit }, errors){
    commit('set_section_errors', {
      section: 'fullscreen',
      errors
    })
  },

  setHalfScreenForm({ commit }, form){
    commit('set_section', {
      section: 'halfscreen',
      content: form?.content,
    })
  },

  reset({ commit, dispatch }){
    commit('set_section', { section: 'mainscreen', ...initialMainscreen });
    commit('set_section', { section: 'fullscreen', ...initialFullscreen });
    commit('set_section', { section: 'halfscreen', ...initialHalfscreen });
    dispatch('isNotSubmitting');
    dispatch('enableScreenshot');
  },

  resetSections({ commit, dispatch }){
    commit('set_section', {
      section: 'mainscreen',
      ...initialMainscreen
    });
    dispatch('isNotSubmitting');
    dispatch('resetFullscreenSection');
    dispatch('resetHalfscreenSection')
  },
  
  closeFullscreen({ state, dispatch }){
    dispatch('resetFullscreenSection');
    if (state.mainscreen.no_screenshot){
      dispatch('disableScreenshot');
    } else {
      dispatch('enableScreenshot');
    }
  },

  resetFullscreenSection({ commit, dispatch }){
    commit('set_section', {
      section: 'fullscreen',
      ...initialFullscreen
    });
    dispatch('resetHalfscreenSection')
  },

  resetHalfscreenSection({ commit }){
    commit('set_section', {
      section: 'halfscreen',
      ...initialHalfscreen,
    })
  },

  clearCurrentFieldErrors({state, commit}){
    if(state.fullscreen.content){
      commit('clear_section_errors', 'fullscreen');
    } else {
      commit('clear_section_errors', 'mainscreen');
    }
  },

  isNotSubmitting({ commit }){
    commit('set_is_submitting', false);
  },

  isSubmitting({ commit }){
    commit('set_is_submitting', true);
  },

  disableScreenshot({ state, commit }){
    if (!state.noScreenshot){
      console.log('DISABLING SCREEN CAPTURE');
      bridge.disableScreenCapture();
      commit('set_no_screenshot', true);
    } 
  },

  enableScreenshot({ state, commit }){
    if (state.noScreenshot){
      console.log('ENABLING SCREEN CAPTURE');
      bridge.enableScreenCapture();
      commit('set_no_screenshot', false);
    }
  }

}

const getters = {
  getCurrentFormCheckboxCheckedCount(state){
    let curContent = state.fullscreen.content ?? state.mainscreen.content;
    let curValues = state.fullscreen.content ? state.fullscreen.values : state.mainscreen.values;
    let ids = curContent
      .filter(el => el.type === 'checkbox')
      .map(el => el.id);
    return ids.filter(id => curValues[id]).length;
  }
}

const initialMainscreen = {
  content: [],
  footer_content: undefined,
  footer_color: undefined,
  errors: {},
  is_centered: false,
  validations: {},
  values: {},
  watermark_image: undefined,
}

const initialFullscreen = {
  content: undefined,
  footer_content: undefined,
  footer_color: undefined,
  errors: {},
  has_header_x_close: undefined,
  is_centered: false,
  validations: {},
  values: {},
  watermark: undefined,
}

const initialHalfscreen = {
  content: undefined,
}

const getContentValidations = (content) => (
  content.reduce((acc, component) => {
    if (component?.validations_rules){
      acc[component.id]= component.validations_rules
    }
    return acc;
  }, {})
);

export const form = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
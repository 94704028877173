const state = () => ({
  title: undefined,
  loading_progress: 0,
  is_loading: false,
  is_page_rendering: false,
})

const mutations = {
  'set_loading_progress'(state, val){
    state.loading_progress = val;
  },
  'set_is_page_rendering'(state, val){
    state.is_page_rendering = val;
  },
  'set_is_loading'(state, val){
    state.is_loading = val;
  }
}

const actions = {
  setLoadingProgress({commit}, progress){
    commit(
      'set_loading_progress', 
      (progress.loaded * 100 / progress.total)
    );
  },
  loadingStarted({ commit }){
    commit('set_is_loading', true);
  },
  loadingFinished({ commit }){
    commit('set_is_loading', false);
  },
  pageRenderingStart({ commit }){
    commit('set_is_page_rendering', true);
  },
  pageRenderingEnd({ commit }){
    commit('set_is_page_rendering', false);
  },
}

export const pdf = {
  namespaced: true,
  state,
  mutations,
  actions,
}
import wayApi from "@/helpers/wayApi.js";
import baseApi from "@/helpers/baseApi.js";
import odooApi from "@/helpers/odooApi.js";
import marketplaceApi from "@/helpers/marketplaceApi.js";
import merchApi from "@/helpers/merchApi.js"
import dashboardApi from "@/helpers/dashboardApi.js";

const setAuthToken = (token) => {
  baseApi.defaults.headers['Authorization'] = 'Token '+ token;
  wayApi.defaults.headers['Authorization'] = 'Token '+ token;
  odooApi.defaults.headers['Authorization'] = 'Token '+ token;
  dashboardApi.defaults.headers['Authorization'] = 'Token ' + token;
  if (process.env.NODE_ENV === "development") {
    marketplaceApi.defaults.headers['Authorization'] = 'Token 7866178473b210e6f3616831c3ae06e4e22993e9';
    merchApi.defaults.headers['Authorization'] = 'Token a1308753ea00b58b223b91049544035cae6154d6';
  } else {
    merchApi.defaults.headers['Authorization'] = `Token ${token}`;
    marketplaceApi.defaults.headers['Authorization'] = 'Token '+ token;
  }
}

export {
  setAuthToken
}

import dashboardApi from '@/helpers/dashboardApi';
import { generateWatermarkImageUri } from "@/helpers/utils";

const state = () => ({
  projectId: null,
  watermark: null,

// Engineering
  mdrPickerDate: null,
  mdrMinDate: null,

// Bugdet
  devDate: null,
  finDate: null,
  budgetMinDate: null,

// Oto (supply)
  otoMinDate: null,
  otoDatePicker: null,

// Buildint (BE)
  beDatePicker: null,
  beMinDate: null,
  beError: false,

// Schedule (PNR) 
  docsPicker: null,
  docsTotalPicker: null,
  docsMinDate: null,
  docsError: false
})

const mutations = {

  // watermark
  'setWatermark'(state, payload) {
    if(state.watermark === null) {
      state.watermark = payload;
    }
  },

/*
  Engineering
*/

  'setMdrDate'(state, payload) {
    if (state.mdrPickerDate === null) {
      state.mdrPickerDate = payload;
    }
  },
  'resetMdrDates'(state) {
    state.mdrPickerDate = null;
    state.mdrMinDate = null;
  },
  'setMdrMinDate'(state, payload) {
    state.mdrMinDate = payload;
  },
  'changedMdrDate'(state, payload) {
    state.mdrPickerDate = payload;
  },

/*
  Budget
*/

  // 1. set min date for all
  'budgetMinDate'(state, payload) {
    state.budgetMinDate = payload;
  },

  // 2. dev
  'setDevDate'(state, payload) {
    if (state.devDate === null) {
      state.devDate = payload;
    }
  },
  'changeDevDate'(state, payload) {
    state.devDate = payload;
  },

  // 3. fin
  'setFinDate'(state, payload) {
    if (state.finDate === null) {
      state.finDate = payload;
    }
  },
  'changeFinDate'(state, payload) {
    state.finDate = payload;
  },
  'resetBudgetDates'(state) {
    state.devDate = null;
    state.finDate = null;
    state.budgetMinDate = null;
  },

/*
  Oto (supply)
*/

  'setOtoMinDate'(state, payload) {
    state.otoMinDate = payload;
  },
  'setOtoDatePicker'(state, payload) {
    if (state.otoDatePicker === null) {
      state.otoDatePicker = payload;
    }
  },
  'changeOtoDatePicker'(state, payload) {
    state.otoDatePicker = payload;
  },
  'resetOto'(state) {
    state.otoDatePicker = null;
    state.otoMinDate = null;
  },

/*
  Building (BE)
*/

  'setBeDatePicker'(state, payload) {
    if (state.beDatePicker === null) {
      state.beDatePicker = payload;
    }
  },
  'setBeMinDate'(state, payload) {
    state.beMinDate = payload;
  },
  'changeBeDate' (state, payload) {
    state.beDatePicker = payload;
  },
  'resetBE' (state) {
    state.beDatePicker = null;
    state.beMinDate = null;
  },
  'setBeError' (state, payload) {
    state.beError = payload;
  },
  'resetBeError' (state) {
    state.beError = false;
  },

/*
  Docs (Schedule)
*/

  'setDocsPicker'(state, payload) {
    if (state.docsPicker === null) {
      state.docsPicker = payload
    }
  },
  'setDocsTotalPicker'(state, payload) {
    if (state.docsTotalPicker === null) {
      state.docsTotalPicker = payload
    }
  },
  'changeDocsPicker'(state, payload) {
    state.docsPicker = payload;
  },
  'changeTotalPicker'(state, payload) {
    state.docsTotalPicker = payload;
  },
  'setDocsMinDate'(state, payload) {
    state.docsMinDate = payload;
  },
  'resetDocs'(state) {
    state.docsPicker = null;
    state.docsTotalPicker = null;
    state.docsMinDate = null;
  },
  'setDocsError'(state, payload) {
    state.docsError = payload;
  },
  'resetDocsError'(state) {
    state.docsError = false;
  },
}


const actions = {

  // get watermark
  
  async watermark({commit}, payload) {
    try {
      const res = await dashboardApi.post('/watermark/', payload);
      const generated = generateWatermarkImageUri(res.data[0]);
      commit('setWatermark', generated);
    } catch (error) {
      /* empty */
    }
  },
  /*
    Engineering
  */
    async setMdrDate({commit}, payload) {
      try {
        const res = await dashboardApi(`/project/${payload}/mdr/date/minmax/`);
        commit('setMdrDate', res.data.max);
        commit('setMdrMinDate', res.data.min);
      } catch (error) {
        console.log(error)
      }
    },
    changedMdrDate({commit}, payload) {
      commit('changedMdrDate', payload);
    },
    resetMdrDates({commit}) {
      commit('resetMdrDates');
    },

  /*
    Budget
  */

    // 1. set init dates
    async setBudgetInitDates({commit}, payload) {
      try {
        const res = await dashboardApi(`/project/${payload}/budget/total/date/minmax/`);
        const {max: initDate, min} = res.data;

        // set min date for all
        commit('budgetMinDate', min);
        // init dates
        commit('setDevDate', initDate);
        commit('setFinDate', initDate);
      } catch (error) {
        console.log(error);
      }
    },

    // 2. dev
    changeDevDate({commit}, payload) {
      commit('changeDevDate', payload);
    },

    // 3. fin
    changeFinDate({commit}, payload) {
      commit('changeFinDate', payload);
    },

    // 4. reset budget dates
    resetBudgetDates({commit}) {
      commit('resetBudgetDates');
    },
  
  /*
    Oto(supply)
  */

    async setOtoMinDate({commit}, payload) {
      try {
        const res = await dashboardApi(`/project/${payload}/oto/date/minmax/`);
        const {max: initDate, min} = res.data;
        commit('setOtoMinDate', min);
        commit('setOtoDatePicker', initDate);
      } catch (error) {
        console.log('error');
      }
    },
    changeOtoDatePicker({commit}, payload) {
      commit('changeOtoDatePicker', payload);
    },
    resetOto({commit}) {
      commit('resetOto');
    },

  /*
    Building (BE)
  */
    
    async setBeDatePicker({commit}, payload) {
      try {
        const res = await dashboardApi(`/project/${payload}/building/be/date/minmax/`);
        const {max: initDate, min} = res.data;
        if (initDate === null) {
          commit('setBeError', true);
        }
        commit('setBeDatePicker', initDate);
        commit('setBeMinDate', min);
      } catch (error) {
        console.log(error);
      }
    },
    changeBeDate({commit}, payload) {
      commit('changeBeDate', payload);
    },
    resetBE ({commit}) {
      commit('resetBE');
    },
    resetBeError ({commit}) {
      commit('resetBeError');
    },
  /*
    Docs (PNR)
  */
    async setDocsPicker({commit}, payload) {
      try {
        const res = await dashboardApi(`/project/${payload}/docs/date/minmax/`);
        const { max: initDate, min} = res.data;
        if (initDate === null) {
          commit('setDocsError', true)
        }
        commit('setDocsPicker', initDate);
        commit('setDocsTotalPicker', initDate);
        commit('setDocsMinDate', min);
      } catch (error) {
        console.log(error);
      }
    },
    changeDocsPicker({commit}, payload) {
      commit('changeDocsPicker', payload)
    },
    changeTotalPicker({commit}, payload) {
      commit('changeTotalPicker', payload)
    },
    resetDocs({commit}) {
      commit('resetDocs');
    },
    resetDocsError({commit}) {
      commit('resetDocsError')
    },
}
const getters = {
/*
  Engineering
*/
  
  'mdrPickerDate': state => {
    return state.mdrPickerDate;
  },

  /*
    Budget
  */

  'devDate': state => {
    return state.devDate;
  },
  'finDate': state => {
    return state.finDate;
  },
  'budgetMinDate': state => {
    return state.budgetMinDate;
  },

  /*
    Oto(supply)
  */

  otoPickerDate: state => {
    return state.otoDatePicker;
  },
  otoMinDate: state => {
    return state.otoMinDate;
  },

/*
  Building (BE)
*/

  bePicker: state => {
    return state.beDatePicker;
  },
  beMinDate: state => {
    return state.beMinDate;
  },
  beError: state => state.beError,

/*
  Docs (PNR)
*/

  docsPicker: state => state.docsPicker,
  totalPicker: state => state.docsTotalPicker,
  docsMinDate: state => state.docsMinDate,
  docsError: state => state.docsError,
  watermark: state => state.watermark,
}

export const cp = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
<template>
  <main class="layout__body">
    <SubMenu 
      :disabled="reloading"
      :rounded="false"
      :rounded-top="true"
      :items-navigate-back-to-parent="false"
      :items="get_burger_menu_items"
      @afterNav="$store.dispatch('layout/hideBurgerContent');"
    />
    <AppBurgerBodyItem
      :icon="require('@/assets/svg/burger_earth.svg')"
      :iconRotate="reloading"
      :title="$translate('burger_lang_title')"
      :text-right="$translate('burger_lang_' + language)"
      :right-arrow="true"
      :disabled="reloading"
      @click.native="showLangDrawer=true"
    />
    <AppBurgerBodyItem
      :icon="require('@/assets/svg/burger_onboard.svg')"
      :iconRotate="reloading"
      :title="$translate('burger_onboard')"
      :disabled="reloading"
      @click.native="$store.dispatch('mainPage/setNotOnboarded');"
    />
    <AppBurgerBodyItem
      :icon="require('@/assets/svg/nav-menu_refresh-orange.svg')"
      :iconRotate="reloading"
      :title="$translate('burger_reload')"
      :disabled="reloading"
      @click.native="handleReloadClick"
    />
    <LanguageBottomDrawer 
      v-if="showLangDrawer"
      @close="showLangDrawer = false"
    />
  </main>
</template>
<script>
import SubMenu from "@/components/Main/SubMenu.vue";
import AppBurgerBodyItem from './partials/AppBurgerBodyItem.vue';
import LanguageBottomDrawer from './partials/LanguageBottomDrawer.vue';
import { postCustomEvent } from "@/helpers/amplitude";
import { mapGetters, mapState } from 'vuex';
export default {
  components: { 
    AppBurgerBodyItem,
    LanguageBottomDrawer,
    SubMenu, 
  },
  name: 'AppBurgerBody',
  data(){
    return {
      reloading: false,
      showLangDrawer: false,
    }
  },
  
  computed: {
    ...mapGetters('mainPage', ['get_burger_menu_items']),
    ...mapState('layout', ['language'])
  },

  methods: {

    handleBurgerItemClick(to){
      if (this.reloading) return;
      this.$router.push(to);
      this.$store.dispatch('layout/hideBurgerContent');
    },

    async handleReloadClick(){
      if (this.reloading) return;
      this.reloading = true;
      try {
        await postCustomEvent('Кнопка рестарт', {timeout: 1000});
      } finally {
        location.reload();
      }
    }
  },
}
</script>
<style lang="scss">
.lang-drawer-content{
  &__item{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
    gap: 10px;
    background: #FAFAFA;
    margin: 0 16px 2px;
    &:first-child{
      border-radius: 16px 16px 0px 0px;
    }
    &:last-child{
      border-radius: 0px 0px 16px 16px;
    }
    &__text{
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: 0.02em;
      color: #101828;
    }
  }
}
</style>
import aituBridge from "@btsd/aitu-bridge";
import * as Sentry from "@sentry/vue";
import { executeInQueue } from './bridgeQueue';
import * as ortaBridge from '@orta/bridge';

const detectedBridgePlatform = aituBridge.isSupported() ? 
  'aitu-bridge' :
  ortaBridge.isWebViewBridgeAvailable() ? 
  'orta-bridge' :
  undefined;

const bridgeInstance = ortaBridge.isWebViewBridgeAvailable() 
  ? ortaBridge
  : aituBridge;
  
const getMe = async (...args) => {
  try {
    let response;
    if (detectedBridgePlatform === 'aitu-bridge'){
      response = await executeInQueue(aituBridge.getMe, args);
    } else if (detectedBridgePlatform === 'orta-bridge'){
      response = await ortaBridge.getMe();
    }
    logEvent({
      methodName: 'getMe', 
      response,
      level: 'log'
    })
    return response;
  } catch(error) {
    logEvent({
      methodName: 'getMe', 
      response: error,
      level: 'error'
    })
    logBridgeError('getMe');
    return Promise.reject(new Error(error));
  }
};

const getPhone = async (...args) => {
  try {
    let response;
    if (detectedBridgePlatform === 'aitu-bridge'){
      response = await executeInQueue(aituBridge.getPhone, args);
    } else if (detectedBridgePlatform === 'orta-bridge'){
      response = {};
    }
    logEvent({
      methodName: 'getPhone', 
      response,
      level: 'log'
    })
    return response;
  } catch(error) {
    logEvent({
      methodName: 'getPhone', 
      response: error,
      level: 'error'
    })
    logBridgeError('getPhone');
    return Promise.reject(new Error(error));
  }
};

const setCustomBackArrowMode = async (...args) => {
  try {
    let response;
    if (detectedBridgePlatform === 'aitu-bridge'){
      response = await executeInQueue(aituBridge.setCustomBackArrowMode, args);
    } else if (detectedBridgePlatform === 'orta-bridge'){
      response = await ortaBridge.updateNavigationBar({
        setCustomBackArrowMode: args[0],
      })
    }
    logEvent({
      methodName: 'setCustomBackArrowMode',
      response,
      level: 'log'
    });
    return response;
  } catch(error) {
    console.error(error);
    logEvent({
      methodName: 'setCustomBackArrowMode',
      response: error,
      level: 'error'
    });
    logBridgeError('setCustomBackArrowMode');
    return Promise.reject(new Error(error));
  }
};

const getCustomBackArrowMode = async (...args) => {
  try {
    let response;
    if (detectedBridgePlatform === 'aitu-bridge'){
      response = await executeInQueue(bridgeInstance.getCustomBackArrowMode, args);
    } else if (detectedBridgePlatform === 'orta-bridge'){
      response = await ortaBridge.getCustomBackArrowMode();
    }
    logEvent({
      methodName: 'getCustomBackArrowMode', 
      response,
      level: 'log'
    })
    return response;
  } catch(error) {
    logEvent({
      methodName: 'getCustomBackArrowMode', 
      response: error,
      level: 'error'
    })
    logBridgeError('getCustomBackArrowMode');
    return Promise.reject(new Error(error));
  }
};
const setCustomBackArrowVisible = async (...args) => {
  try {
    let response;
    if (detectedBridgePlatform === 'aitu-bridge'){
      response = await executeInQueue(bridgeInstance.setCustomBackArrowVisible, args);  
    } else if (detectedBridgePlatform === 'orta-bridge'){
      response = await ortaBridge.setCustomBackArrowVisible({
        visible: args[0],
      })
    }
    logEvent({
      methodName: 'setCustomBackArrowVisible', 
      response,
      level: 'log'
    })
    return response;
  } catch(error) {
    logEvent({
      methodName: 'setCustomBackArrowVisible', 
      response: error,
      level: 'error'
    })
    logBridgeError('setCustomBackArrowVisible');
    return Promise.reject(new Error(error));
  }
};

const setCustomBackArrowOnClickHandler = async (...args) => {
  console.log(7)
  try {
    let response;
    if (detectedBridgePlatform === 'aitu-bridge'){
      response = await executeInQueue(aituBridge.setCustomBackArrowOnClickHandler, args);
    } else if (detectedBridgePlatform === 'orta-bridge'){
      console.log('setting onOrtaBridgeBackArrowClick');
      console.log(args[0]);
      window.onOrtaBridgeBackArrowClick = args[0];
    }
    logEvent({
      methodName: 'setCustomBackArrowOnClickHandler', 
      response,
      level: 'log'
    })
    return response;
  } catch(error) {
    logEvent({
      methodName: 'setCustomBackArrowOnClickHandler', 
      response: error,
      level: 'error'
    })
    logBridgeError('setCustomBackArrowOnClickHandler');
    return Promise.reject(new Error(error));
  }
};

const setHeaderMenuItems = async (...args) => {
  try {
    let response;
    if (detectedBridgePlatform === 'aitu-bridge'){
      response = await executeInQueue(bridgeInstance.setHeaderMenuItems, args);
    } else if (detectedBridgePlatform === 'orta-bridge'){
      response = await ortaBridge.UpdateMenuItems(...args);
    }
    logEvent({
      methodName: 'setHeaderMenuItems', 
      response,
      level: 'log'
    })
    return response;
  } catch(error) {
    logEvent({
      methodName: 'setHeaderMenuItems', 
      response: error,
      level: 'error'
    })
    logBridgeError('setHeaderMenuItems');
    return Promise.reject(new Error(error));
  }
};

const setHeaderMenuItemClickHandler = async (...args) => {
  try {
    let response;
    if (detectedBridgePlatform === 'aitu-bridge'){
      response = await executeInQueue(bridgeInstance.setHeaderMenuItemClickHandler, args);
    } else if (detectedBridgePlatform === 'orta-bridge'){
      response = await ortaBridge.setHeaderMenuItemClickHandler(args[0]);
    }
    logEvent({
      methodName: 'setHeaderMenuItemClickHandler', 
      response,
      level: 'log'
    })
    return response;
  } catch(error) {
    logEvent({
      methodName: 'setHeaderMenuItemClickHandler', 
      response: error,
      level: 'error'
    })
    logBridgeError('setHeaderMenuItemClickHandler');
    return Promise.reject(new Error(error));
  }
}

const disableScreenCapture = async (...args) => {
  try {
    let response;
    if (detectedBridgePlatform === 'aitu-bridge'){
      response = await executeInQueue(bridgeInstance.disableScreenCapture, args);
    } else if (detectedBridgePlatform === 'orta-bridge'){
      response = await ortaBridge.disableScreenCapture(); 
    }
    logEvent({
      methodName: 'disableScreenCapture', 
      response,
      level: 'log'
    })
    return response;
  } catch(error) {
    logEvent({
      methodName: 'disableScreenCapture', 
      response: error,
      level: 'error'
    })
    logBridgeError('disableScreenCapture');
    return Promise.reject(new Error(error));
  }
}

const enableScreenCapture = async (...args) => {
  try {
    let response;
    if (detectedBridgePlatform === 'aitu-bridge'){
      response = await executeInQueue(bridgeInstance.enableScreenCapture, args);
    } else if (detectedBridgePlatform === 'orta-bridge'){
      response = await ortaBridge.enableScreenCapture(); 
    }
    logEvent({
      methodName: 'enableScreenCapture', 
      response,
      level: 'log'
    })
    return response;
  } catch(error) {
    logEvent({
      methodName: 'enableScreenCapture', 
      response: error,
      level: 'error'
    })
    logBridgeError('enableScreenCapture');
    return Promise.reject(new Error(error));
  }
}

const setTabActiveHandler = async (...args) => {
  try {
    let response;
    if (detectedBridgePlatform === 'aitu-bridge'){
      response = await executeInQueue(bridgeInstance.setTabActiveHandler, args);
    }
    logEvent({
      methodName: 'setTabActiveHandler', 
      response,
      level: 'log'
    })
    return response;
  } catch(error) {
    logEvent({
      methodName: 'setTabActiveHandler', 
      response: error,
      level: 'error'
    })
    logBridgeError('setTabActiveHandler');
    return Promise.reject(new Error(error));
  }
}

const getQr = async (...args) => {
  try {
    let response;
    if (detectedBridgePlatform === 'aitu-bridge'){
      response = await executeInQueue(bridgeInstance.getQr, args)
    } else if (detectedBridgePlatform === 'orta-bridge'){
      response = await ortaBridge.getQr();
    }
    logEvent({
      methodName: 'getQr', 
      response,
      level: 'log'
    })
    return response;
  } catch(error) {
    logEvent({
      methodName: 'getQr', 
      response: error,
      level: 'error'
    })
    logBridgeError('getQr');
    return Promise.reject(new Error(error));
  }
};

const setTitle = async (...args) => {
  try {
    let response
    if (detectedBridgePlatform === 'aitu-bridge'){
      response = await executeInQueue(bridgeInstance.setTitle, args);
    } else if (detectedBridgePlatform === 'orta-bridge'){
      response = ortaBridge.updateNavigationBar({
        title: args[0],
      })
    }
    logEvent({
      methodName: 'setTitle', 
      response,
      level: 'log'
    })
    return response;
  } catch(error) {
    logEvent({
      methodName: 'setTitle', 
      response: error,
      level: 'error'
    })
    logBridgeError('setTitle');
    return Promise.reject(new Error(error));
  }
};

const shareFile = async (...args) => {
  try {
    let response;
    if (detectedBridgePlatform === 'aitu-bridge'){
      response = await executeInQueue(bridgeInstance.shareFile, args)
    } else if (detectedBridgePlatform === 'orta-bridge'){
      response = await ortaBridge.shareFile(args[0])
    }
    logEvent({
      methodName: 'shareFile', 
      response,
      level: 'log'
    })
    return response;
  } catch(error) {
    logEvent({
      methodName: 'shareFile', 
      response: error,
      level: 'error'
    })
    logBridgeError('shareFile');
    return Promise.reject(new Error(error));
  }
};

const share = async (...args) => {
  try {
    let response;
    if (detectedBridgePlatform === 'aitu-bridge'){
      response = await executeInQueue(bridgeInstance.share, args);
    } else if (detectedBridgePlatform === 'orta-bridge'){
      response = await ortaBridge.share(...args);
    }
    logEvent({
      methodName: 'share', 
      response,
      level: 'log'
    })
    return response;
  } catch(error) {
    logEvent({
      methodName: 'share', 
      response: error,
      level: 'error'
    })
    logBridgeError('share');
    return Promise.reject(new Error(error));
  }
};

const copyToClipboard = async (...args) => {
  try {
    let response;
    if (detectedBridgePlatform === 'aitu-bridge'){
      response = await executeInQueue(bridgeInstance.copyToClipboard, args)
    } else if (detectedBridgePlatform === 'orta-bridge'){
      response =  await ortaBridge.copyToClipboard(...args);
    }
    logEvent({
      methodName: 'copyToClipboard', 
      response,
      level: 'log'
    })
    return response;
  } catch(error) {
    logEvent({
      methodName: 'copyToClipboard', 
      response: error,
      level: 'error'
    })
    logBridgeError('copyToClipboard');
    return Promise.reject(new Error(error));
  }
};

const enableNotifications = async (...args) => {
  try {
    let response;
    if (detectedBridgePlatform === 'aitu-bridge'){
      response = await executeInQueue(bridgeInstance.enableNotifications, args);
    } else if (detectedBridgePlatform === 'orta-bridge'){
      response = await ortaBridge.enableNotifications(...args);
    }
    logEvent({
      methodName: 'enableNotifications', 
      response,
      level: 'log'
    })
    return response;
  } catch(error) {
    logEvent({
      methodName: 'enableNotifications', 
      response: error,
      level: 'error'
    })
    logBridgeError('enableNotifications');
    return Promise.reject(new Error(error));
  }
};

const setStorageItem = async (...args) => {
  try {
    let response;
    if (!detectedBridgePlatform && process.env.NODE_ENV === "development") {
      window.localStorage.setItem('STORAGE_'+args[0], args[1]);
    } else if (detectedBridgePlatform === 'aitu-bridge'){
      response = await executeInQueue(
        bridgeInstance.storage.setItem, 
        [
          process.env.VUE_APP_STORAGE_PREFIX+'_'+args[0], 
          args[1]
        ]
      );
    } else {
      console.log('no orta bridge support')
    }
    logEvent({
      methodName: 'storage.setItem', 
      response,
      level: 'log'
    })
    return response;
  } catch(error) {
    logEvent({
      methodName: 'storage.setItem', 
      response: error,
      level: 'error'
    })
    logBridgeError('storage.setItem');
    return Promise.reject(new Error(error));
  }
};

const getStorageItem = async (...args) => {
  try {
    let response;
    if (!detectedBridgePlatform && process.env.NODE_ENV === "development") {
      response = window.localStorage.getItem('STORAGE_'+args[0]);
    } else if (detectedBridgePlatform === 'aitu-bridge'){
      response = await executeInQueue(
        bridgeInstance.storage.getItem, 
        [ process.env.VUE_APP_STORAGE_PREFIX+'_'+args[0] ]
      );
    } else if (detectedBridgePlatform === 'orta-bridge'){
      console.log('no orta bridge support')
    }
    logEvent({
      methodName: 'storage.getItem', 
      response,
      level: 'log'
    })
    return response;
  } catch(error) {
    logEvent({
      methodName: 'storage.getItem', 
      response: error,
      level: 'error'
    })
    logBridgeError('storage.getItem');
    return Promise.reject(new Error(error));
  }
};

const clearStorage = async (...args) => {
  try {
    let response;
    if (detectedBridgePlatform === 'aitu-bridge'){
       response = await executeInQueue(bridgeInstance.storage.clear, args);
    } else if (detectedBridgePlatform === 'orta-bridge'){
      // orta logic here
      console.log('no orta bridge support')
    }
    logEvent({
      methodName: 'storage.clear', 
      response,
      level: 'log'
    })
    return response;
  } catch(error) {
    logEvent({
      methodName: 'storage.clear', 
      response: error,
      level: 'error'
    })
    logBridgeError('storage.clear');
    return Promise.reject(new Error(error));
  }
};

const logBridgeError = (method) => {
  Sentry.captureException(new Error('Bridge error: '+method), (scope) => {
    scope.setTags({
      method,
      platform: detectedBridgePlatform,
    })
  });
}

const logEvent = ({methodName, response, level}) => {
  let data = typeof response === 'object' ? response : undefined;
  let message =  methodName 
    + ' response: '
    + ( typeof response === 'object' 
      ? ''
      : typeof response === 'string'
      ? `"${response}"`
      : response
    );
  Sentry.addBreadcrumb({
    category: detectedBridgePlatform || "bridge",
    message,
    data,
    level,
  });
}


export default {
  clearStorage,
  copyToClipboard,
  disableScreenCapture,
  enableScreenCapture,
  enableNotifications,
  getCustomBackArrowMode,
  getMe,
  getPhone,
  getStorageItem,
  getQr,
  detectedBridgePlatform,
  setCustomBackArrowMode,
  setCustomBackArrowOnClickHandler,
  setCustomBackArrowVisible,
  setHeaderMenuItems,
  setHeaderMenuItemClickHandler,
  setStorageItem,
  setTabActiveHandler,
  setTitle,
  share,
  shareFile,
}
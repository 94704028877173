import axios from "axios";

const merchApiInstance = axios.create({
  baseURL: (process.env.VUE_APP_MERCH_HOST || ''),
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
});

merchApiInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 502) {
      window.location.href = '/s/merch/under_maintenance';
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

export default merchApiInstance;
export { merchApiInstance as merchApi };

import marketplaceApi from "@/helpers/marketplaceApi";

const state = () => ({
  api_url: process.env.VUE_APP_MARKETPLACE_HOST,
  marketUser: undefined,
  marketToken: '',
  limit: '',
  stationeryLimit: '',
  limitType: 'garwin',
  addressList: [],
  mvzList: [],
  permsList: [],
  city: null,
  statuses: undefined,
  cart: [],
  cart_products: [],
  delegates: [],
  cancelQueue: [],
  ordersPaginationInfo: undefined,
  orders: [],
  orderById: undefined,
  approveOrdersPaginationInfo: undefined,
  approveOrders: [],
  approveOrder: undefined,
  acceptOrdersPaginationInfo: undefined,
  acceptOrders: [],
  acceptOrder: undefined,
  wide_subcategories: false,
  wide_sub_subcategories: false,
  wide_sub_sub_subcategories: false,
  categories: [],
  subcategoryById: undefined,
  sub_subcategoryById: undefined,
  sub_sub_subcategoryById: undefined,
  productsPaginationInfo: undefined,
  productsByCategory: [],
  product: undefined,
  productReviews: [],
  searchResult: [],
  brandsList: [],
  queryParams: new URLSearchParams(),
  filtersPaginationInfo: undefined,
  filteredProducts: undefined,

  loginError: undefined,
  cartError: undefined,
  delegateError: undefined,
  orderError: undefined,
  categoryError: undefined,
});

const mutations = {

  // ERRORS
  setLoginError(state, payload) {
    state.loginError = payload;
  },
  setCartError(state, payload) {
    state.cartError = payload;
  },
  setDelegatesError(state, payload) {
    state.delegateError = payload;
  },
  setOrderError(state, payload) {
    state.orderError = payload;
  },
  setCategoryError(state, payload) {
    state.categoryError = payload;
  },
  // ERRORS ^

  setCity(state, payload) {
    state.city = payload
  },

  setUserInfo(state, payload) {
    state.marketUser = payload;
    state.marketToken = payload.token;
    if (payload.order_limit) {
      state.limit = parseFloat(payload.order_limit);
    } else state.limit = '';
    if (payload.stationery_limit) {
      state.stationeryLimit = parseFloat(payload.stationery_limit);
    } else state.stationeryLimit = '';
    state.addressList = payload.addresses;
    state.mvzList = payload.mvz;
    state.permsList = payload.perms;
  },

  setOrderStatuses(state, payload) {
    state.statuses = payload;
  },

  setUserCart(state, payload) {
    state.cart = payload;
    state.cart_products = (payload?.lines || []).reduce((acc, item) => {
      return [...acc, ...item.products]
    }, [])
  },

  setDelegates(state, payload) {
    state.delegates = payload;
  },

  setOrdersPaginationInfo(state, payload) {
    state.ordersPaginationInfo = payload;
  },

  setUserOrders(state, payload) {
    state.orders = state.orders.concat(payload);
  },

  'clear-user-orders'(state) {
    state.ordersPaginationInfo = undefined;
    state.orders = [];
  },

  setOrderById(state, payload) {
    state.orderById = payload;
  },

  'clear-order-by-id'(state) {
    state.orderById = undefined;
  },

  setApproveOrdersPaginationInfo(state, payload) {
    state.approveOrdersPaginationInfo = payload;
  },

  setApproveOrders(state, payload) {
    state.approveOrders = state.approveOrders.concat(payload);
  },

  'clear-approve-orders'(state) {
    state.approveOrdersPaginationInfo = undefined;
    state.approveOrders = [];
  },

  setApproveOrder(state, payload) {
    state.approveOrder = payload;
  },

  'clear-approve-order'(state) {
    state.approveOrder = undefined;
  },

  setAcceptOrdersPaginationInfo(state, payload) {
    state.acceptOrdersPaginationInfo = payload;
  },

  setAcceptOrders(state, payload) {
    state.acceptOrders = state.acceptOrders.concat(payload);
  },

  'clear-accept-orders'(state) {
    state.acceptOrdersPaginationInfo = undefined;
    state.acceptOrders = [];
  },

  setAcceptOrder(state, payload) {
    state.acceptOrder = payload;
  },

  'clear-accept-order'(state) {
    state.acceptOrder = undefined;
  },

  'set-wide-subcategories'(state) {
    state.wide_subcategories = true;
  },

  'reset-wide-subcategories'(state) {
    state.wide_subcategories = false;
  },

  'set-wide-sub-subcategories'(state) {
    state.wide_sub_subcategories = true;
  },

  'reset-wide-sub-subcategories'(state) {
    state.wide_sub_subcategories = false;
  },

  'set-wide-sub-sub-subcategories'(state) {
    state.wide_sub_sub_subcategories = true;
  },

  'reset-wide-sub-sub-subcategories'(state) {
    state.wide_sub_sub_subcategories = false;
  },

  setCategories(state, payload) {
    state.categories = payload;
  },

  setLimitType(state, payload) {
    state.limitType = payload;
  },

  setSubcategoryById(state, payload) {
    state.subcategoryById = payload;
  },

  'clear-subcategory'(state) {
    state.subcategoryById = undefined;
  },

  setSubSubcategoryById(state, payload) {
    state.sub_subcategoryById = payload;
  },

  'clear-sub_subcategory'(state) {
    state.sub_subcategoryById = undefined;
  },

  setSubSubSubcategoryById(state, payload) {
    state.sub_sub_subcategoryById = payload;
  },

  'clear-sub_sub_subcategory'(state) {
    state.sub_sub_subcategoryById = undefined;
  },

  setProductsPaginationInfo(state, payload) {
    state.productsPaginationInfo = payload;
  },

  'clear-products-pagination-info'(state) {
    state.productsPaginationInfo = undefined;
  },

  setProductsByCategoryId(state, payload) {
    state.productsByCategory = state.productsByCategory.concat(payload);
  },

  'clear-list-of-products'(state) {
    state.productsByCategory = [];
  },

  setProduct(state, payload) {
    state.product = payload;
  },

  'clear-product'(state) {
    state.product = undefined;
  },

  setProductReviews(state, payload) {
    state.productReviews = payload;
  },

  'clear-product-reviews'(state) {
    state.productReviews = [];
  },

  setSearchResult(state, payload) {
    state.searchResult = payload;
  },

  'clear-search-result'(state) {
    state.searchResult = [];
  },

  setBrandsList(state, payload) {
    state.brandsList = payload;
  },

  'clear-brands-list'(state) {
    state.brandsList = [];
  },

  'set-query-params'(state, params) {
    if (params?.length) {
      state.queryParams.delete(params[0].name);
      params.forEach(param => {
        state.queryParams.append(param.name, param.value);
      })
    } else {
      if (state.queryParams?.has(params.name)) {
        state.queryParams.set(params.name, params.value);
      } else {
        state.queryParams.append(params.name, params.value);
      }
    }
  },

  'delete-query-param'(state, param) {
    state.queryParams.delete(param);
  },

  'clear-query-params'(state) {
    state.queryParams = new URLSearchParams();
  },

  setFiltersPaginationInfo(state, payload) {
    state.filtersPaginationInfo = payload;
  },

  'clear-filters-pagination-info'(state) {
    state.filtersPaginationInfo = undefined;
  },

  setFilteredProducts(state, payload) {
    if (state.filteredProducts) {
      state.filteredProducts = state.filteredProducts.concat(payload)
    } else {
      state.filteredProducts = payload;
    }
  },

  'clear-filtered-products'(state) {
    state.filteredProducts = undefined;
  },

  'add-to-cancel-queue'(state, { is_editing, lineId }) {
    if (is_editing) {
      let cancelingItem = state.cancelQueue.find(line => {
        return line === lineId
      });
      if (cancelingItem) {
        state.cancelQueue = state.cancelQueue.filter(line => {
          return cancelingItem !== line
        })
      } else {
        state.cancelQueue = [
          ...state.cancelQueue,
          lineId,
        ];
      }
    }
  },

  'add-all-to-cancel-queue'(state, { is_editing, lineIds }) {
    if (is_editing) {
      state.cancelQueue = lineIds;
    }
  },

  'clear-cancel-queue'(state) {
    state.cancelQueue = [];
  },
}

const actions = {

  getUserInfo({ commit }) {
    if (marketplaceApi.defaults.headers['Marketauthorization']) {
      delete marketplaceApi.defaults.headers['Marketauthorization'];
    }
    return marketplaceApi.get('/api/myerglogin/').then((res) => {
      if (res?.data) {
        commit('setUserInfo', res.data);
        commit('setCity', res.data?.city);
        marketplaceApi.defaults.headers['Marketauthorization'] = ' Markettoken ' + res.data.token;
      } else {
        throw Error('no user info in response')
      }
    }).catch((error) => {
      if (error) {
        commit('setLoginError', error);
      }
    })
  },

  getOrderStatuses({ commit }) {
    return marketplaceApi.get('/api/statusmap/').then((res) => {
      if (res?.data) {
        commit('setOrderStatuses', res.data);
      } else {
        throw Error('no user info in response')
      }
    })
  },

  getUserCart({ commit }) {
    return marketplaceApi.get('/api/basket/').then((res) => {
      if (res?.data) {
        commit('setUserCart', res.data);
      } else {
        throw Error('no user info in response')
      }
    }).catch((error) => {
      if (error) {
        commit('setCartError', error);
      }
    })
  },

  getDelegates({ commit }) {
    return marketplaceApi.get('/api/delegateorder/users/').then((res) => {
      if (res?.data) {
        commit('setDelegates', res.data);
      } else {
        throw Error('no user info in response')
      }
    }).catch((error) => {
      if (error) {
        commit('setDelegatesError', error);
      }
    })
  },

  setDelegate({}, { orderId, userId }) { // eslint-disable-line
    return marketplaceApi.patch(`/api/delegateorder/${orderId}/`, { "user_id": userId }).then((res) => {
      if (res?.data) {
        return res.data;
      } else {
        throw Error('no user info in response')
      }
    })
  },

  getUserOrders({ state, commit }) {
    let URL = '/api/orders/';
    let page = state.ordersPaginationInfo ? `?page=${state.ordersPaginationInfo.next}` : '';
    return marketplaceApi.get(`${URL}${page}`).then((res) => {
      if (res?.data) {
        commit('setOrdersPaginationInfo', res.data);
        commit('setUserOrders', res.data.results);
      } else {
        throw Error('no user info in response')
      }
    }).catch((error) => {
      if (error) {
        commit('setOrderError', error);
      }
    })
  },

  clearUserOrders({ commit }) {
    commit('clear-user-orders');
  },

  getOrderById({ commit }, orderId) {
    return marketplaceApi.get(`/api/orders/${orderId}/`).then((res) => {
      if (res?.data) {
        commit('setOrderById', res.data);
      } else {
        throw Error('no user info in response')
      }
    })
  },

  clearOrderById({ commit }) {
    commit('clear-order-by-id');
  },

  getApproveOrders({ commit }) {
    let URL = '/api/approves/';
    let page = state.approveOrdersPaginationInfo ? `?page=${state.approveOrdersPaginationInfo.next}` : '';
    return marketplaceApi.get(`${URL}${page}`).then((res) => {
      if (res?.data) {
        commit('setApproveOrdersPaginationInfo', res.data);
        commit('setApproveOrders', res.data.results);
      } else {
        throw Error('no user info in response')
      }
    })
  },

  clearApproveOrders({ commit }) {
    commit('clear-approve-orders');
  },

  getApproveOrder({ commit }, orderId) {
    return marketplaceApi.get(`/api/approves/${orderId}/`).then((res) => {
      if (res?.data) {
        commit('setApproveOrder', res.data);
      } else {
        throw Error('no user info in response')
      }
    })
  },

  clearApproveOrder({ commit }) {
    commit('clear-approve-order');
  },

  getAcceptOrders({ commit }) {
    let URL = '/api/acceptorders/';
    let page = state.acceptOrdersPaginationInfo ? `?page=${state.acceptOrdersPaginationInfo.next}` : '';
    return marketplaceApi.get(`${URL}${page}`).then((res) => {
      if (res?.data) {
        commit('setAcceptOrdersPaginationInfo', res.data);
        commit('setAcceptOrders', res.data.results);
        return res.data;
      } else {
        throw Error('no user info in response')
      }
    })
  },

  clearAcceptOrders({ commit }) {
    commit('clear-accept-orders');
  },

  getAcceptOrder({ commit }, orderNumber) {
    return marketplaceApi.get(`/api/acceptorder/${orderNumber}/`).then((res) => {
      if (res?.data) {
        commit('setAcceptOrder', res.data);
        return res.data;
      } else {
        throw Error('no user info in response')
      }
    })
  },

  clearAcceptOrder({ commit }) {
    commit('clear-accept-order');
  },

  acceptOrderedLines({}, { orderNumber, linesArray }) { // eslint-disable-line
    return marketplaceApi.patch(`/api/acceptorder/${orderNumber}/`, { "accepted_lines": linesArray });
  },

  rejectOrderedLines({}, { orderNumber, linesArray }) { // eslint-disable-line
    return marketplaceApi.patch(`/api/acceptorder/${orderNumber}/`, { "rejected_lines": linesArray });
  },

  approveOrDeclineOrder({}, { orderId, approve, comment }) { // eslint-disable-line
    let payload = { approve, comment };
    return marketplaceApi.patch(`/api/approves/${orderId}/`, payload);
  },

  set_wide_subcategories({ commit }) {
    commit('set-wide-subcategories');
    commit('set-wide-sub-subcategories');
    commit('set-wide-sub-sub-subcategories');
  },

  set_wide_sub_subcategories({ commit }) {
    commit('set-wide-sub-subcategories');
    commit('set-wide-sub-sub-subcategories');
  },

  set_wide_sub_sub_subcategories({ commit }) {
    commit('set-wide-sub-sub-subcategories');
  },

  reset_wide_subcategories({ commit }) {
    commit('reset-wide-subcategories');
    commit('reset-wide-sub-subcategories');
    commit('reset-wide-sub-sub-subcategories');
  },

  reset_wide_sub_subcategories({ commit }) {
    commit('reset-wide-sub-subcategories');
    commit('reset-wide-sub-sub-subcategories');
  },

  reset_wide_sub_sub_subcategories({ commit }) {
    commit('reset-wide-sub-sub-subcategories');
  },

  getCategories({ commit }, payload = 'garwin') {
    return marketplaceApi.get('/api/categories/', {
      params: {
        product_class: payload
      }
    }).then((res) => {
      if (res?.data) {
        commit('setCategories', res.data);
        commit('setLimitType', payload)
      } else {
        throw Error('no user info in response')
      }
    }).catch((error) => {
      if (error) {
        commit('setCategoryError', error);
      }
    })
  },

  getSubcategoryById({ commit }, payload) {
    return marketplaceApi.get(`/api/categories/${payload}/`).then((res) => {
      if (res?.data) {
        commit('setSubcategoryById', res.data);
      } else {
        throw Error('no user info in response')
      }
    })
  },

  clearSubcategory({ commit }) {
    commit('clear-subcategory');
  },

  getSubSubcategoryById({ commit }, payload) {
    return marketplaceApi.get(`/api/categories/${payload}/`).then((res) => {
      if (res?.data) {
        commit('setSubSubcategoryById', res.data);
      } else {
        throw Error('no user info in response')
      }
    })
  },

  clearSubSubcategory({ commit }) {
    commit('clear-sub_subcategory');
  },

  getSubSubSubcategoryById({ commit }, payload) {
    return marketplaceApi.get(`/api/categories/${payload}/`).then((res) => {
      if (res?.data) {
        commit('setSubSubSubcategoryById', res.data);
      } else {
        throw Error('no user info in response')
      }
    })
  },

  clearSubSubSubcategory({ commit }) {
    commit('clear-sub_sub_subcategory');
  },

  getProductsByFilter({ state, commit }, categoryId) {
    let URL = `/api/categories/${categoryId}/products/`;
    let queryLength = state.queryParams.toString().length;
    if (queryLength) {
      let query = queryLength ? `?${state.queryParams}` : '';
      let page = state.filtersPaginationInfo?.next ? `&page=${state.filtersPaginationInfo.next}` : '';
      return marketplaceApi.get(`${URL}${query}${page}`).then((res) => {
        if (res?.data) {
          commit('setFiltersPaginationInfo', res.data);
          commit('setFilteredProducts', res.data.results);
        } else {
          throw Error('no user info in response')
        }
      })
    } else {
      let page = state.productsPaginationInfo ? `?page=${state.productsPaginationInfo.next}` : '';
      return marketplaceApi.get(`${URL}${page}`).then((res) => {
        if (res?.data) {
          commit('setProductsPaginationInfo', res.data)
          commit('setProductsByCategoryId', res.data.results);
        } else {
          throw Error('no user info in response')
        }
      })
    }
  },

  clearFiltersPaginationInfo({ commit }) {
    commit('clear-filters-pagination-info');
  },

  clearProductsByFilter({ commit }) {
    commit('clear-filtered-products');
  },

  clearProductsPaginationInfo({ commit }) {
    commit('clear-products-pagination-info');
  },

  clearListOfProducts({ commit }) {
    commit('clear-list-of-products');
  },

  getProduct({ commit }, payload) {
    return marketplaceApi.get(`/api/products/${payload}/`).then((res) => {
      if (res?.data) {
        commit('setProduct', res.data);
        commit('setLimitType', res.data?.product_class === 'office_supplies' ? 'office_supplies' : 'garwin')
        const url = process.env.VUE_APP_MARKETPLACE_HOST
        let _products = JSON.parse(localStorage.getItem('viewed-items') || "[]")

        _products = _products.filter(p => p.id !== res?.data.id)
        _products = [{
          type: 'tools',
          title: res?.data.title,
          id: res?.data.id,
          image: res?.data?.images?.[0] ? `${url}${res?.data?.images?.[0]}` : '',
          rating: res?.data.rating,
          price: parseFloat(res?.data?.price.excl_tax || 0).toLocaleString("ru") + ' ₸',
          is_in_wishlist: res?.data.is_in_wishlist || false,
          availability: res?.data.availability || {}
        }, ..._products]
        if(_products.length > 4) _products.pop()
        localStorage.setItem('viewed-items', JSON.stringify(_products))
      } else {
        throw Error('no user info in response')
      }
    })
  },

  clearProduct({ commit }) {
    commit('clear-product');
  },

  getProductReviews({ commit }, payload) {
    return marketplaceApi.get(`/api/products/${payload}/reviews/`).then((res) => {
      if (res?.data) {
        commit('setProductReviews', res.data);
      } else {
        throw Error('no user info in response')
      }
    })
  },

  clearProductReviews({ commit }) {
    commit('clear-product-reviews');
  },

  searchForProduct({ commit }, payload) {
    return marketplaceApi.get(`/api/products/search/${payload}/`).then((res) => {
      if (res?.data) {
        commit('setSearchResult', res.data);
      } else {
        throw Error('no user info in response')
      }
    })
  },

  clearSearchResult({ commit }) {
    commit('clear-search-result');
  },

  getBrandsByCategory({ commit }, categoryId) {
    return marketplaceApi.get(`/api/categories/${categoryId}/brands/`).then((res) => {
      if (res?.data) {
        commit('setBrandsList', res.data);
      } else {
        throw Error('no user info in response')
      }
    })
  },

  clearBrandsByCategory({ commit }) {
    commit('clear-brands-list');
  },

  setQueryParams({ commit }, params) {
    commit('set-query-params', params);
  },

  deleteQueryParam({ commit }, param) {
    commit('delete-query-param', param);
  },

  clearQueryParams({ commit }) {
    commit('clear-query-params');
  },

  addToCart({ state, dispatch, getters }, { id, quantity = 1 }) {
    if (getters.in_cart_qty_by_id(id) == 0) {
      dispatch("addProductToCart", { product: id, quantity });
    } else {
      dispatch("editProductInCart", {
        productId: id,
        basket: state.cart.id,
        quantity: getters.in_cart_qty_by_id(id) + quantity
      });
    }
  },

  removeFromCart({ state, dispatch, getters }, id) {
    if (getters.in_cart_qty_by_id(id) == 1) {
      dispatch("deleteProductFromCart", { lineId: getters.line_id_by_product_id(id) });
    } else if (getters.in_cart_qty_by_id(id) > 1) {
      dispatch("editProductInCart", {
        productId: id,
        basket: state.cart.id,
        quantity: getters.in_cart_qty_by_id(id) - 1
      });
    }
  },

  addProductToCart({ dispatch }, payload) {
    return marketplaceApi.post(`/api/basket/add-product/`, payload).then((res) => {
      if (res?.data) {
        dispatch("getUserCart");
      } else {
        throw Error('no user info in response')
      }
    })
  },

  editProductInCart({ dispatch, getters }, { productId, basket, quantity }) {
    let lineId = getters.line_id_by_product_id(productId);
    return marketplaceApi.put(`/api/basket/lines/${lineId}/`, { basket, quantity }).then((res) => {
      if (res?.data) {
        dispatch("getUserCart");
      } else {
        throw Error('no user info in response')
      }
    })
  },

  deleteProductFromCart({ dispatch }, { lineId }) {
    return marketplaceApi.delete(`/api/basket/lines/${lineId}/`).then(() => {
      dispatch("getUserCart");
    })
  },

  checkoutOrder({}, payload) { // eslint-disable-line
    return marketplaceApi.post(`/api/checkout/`, payload).then((res) => {
      if (res?.data) {
        return res.data;
      } else {
        throw Error('no user info in response')
      }
    })
  },

  sendFeedback({}, { productId, feedbackData }) { // eslint-disable-line
    const url = `/api/products/${productId}/reviews/`;
    if (feedbackData.body) {
      return marketplaceApi.post(url, feedbackData).then((res) => {
        if (res?.data) {
          return res.data;
        } else {
          throw Error('no user info in response')
        }
      })
    } else {
      const config = { headers: { 'Content-Type': 'multipart/form-data' } };
      return marketplaceApi.post(url, feedbackData, config).then((res) => {
        if (res?.data) {
          return res.data;
        } else {
          throw Error('no user info in response')
        }
      })
    }
  },

  addToCancelQueue({ commit }, { is_editing, lineId }) {
    commit('add-to-cancel-queue', { is_editing, lineId });
  },

  addAllToCancelQueue({ state, commit }, { is_editing, lineIds }) {
    if (state.cancelQueue.length === lineIds.length) {
      commit('clear-cancel-queue');
    } else {
      commit('add-all-to-cancel-queue', { is_editing, lineIds });
    }
  },

  clearCancelQueue({ commit }) {
    commit('clear-cancel-queue');
  },

  cancelOrderLines({ state, dispatch }, orderId) {
    return marketplaceApi.patch(`/api/orders/${orderId}/`, { cancelled_lines: state.cancelQueue }).then((res) => {
      if (res?.data) {
        dispatch("clearCancelQueue");
        return res.data;
      } else {
        throw Error('no user info in response')
      }
    })
  },
}

const getters = {
  // Category getters
  'all_categories': (state) => {
    return state.categories.concat(
      state.subcategoryById,
      state.sub_subcategoryById,
      state.sub_sub_subcategoryById,
    );
  },

  // Cart getters
  'cart_price_total': (state) => {
    return state.cart.total_excl_tax;
  },

  'cart_qty_total': (state) => {
    return state.cart_products?.reduce((acc, cur) => {
      return acc + cur.quantity || 0;
    }, 0)
  },

  'in_cart_qty_by_id': (state) => (id) => {
    return state.cart_products.find(item => item.product.id === id)?.quantity || 0;
  },

  'line_id_by_product_id': (state) => (id) => {
    return state.cart_products.find(item => item.product.id === id)?.id || 0;
  },

  // Permission getters
  'user__can_order': (state) => {
    return state.permsList?.includes('user.can_order');
  },
  'order__can_approve': (state) => {
    return state.permsList?.includes('order.can_approve');
  },
  'order__can_accept': (state) => {
    return state.permsList?.includes('order.can_accept');
  },

}

export const marketplace = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

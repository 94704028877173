import axios from "axios";

const dashboardApiInstance = axios.create({
  baseURL: (process.env.VUE_APP_ERGCP_HOST || ''),
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
});

export default dashboardApiInstance;
export { dashboardApiInstance as dashboardApi };
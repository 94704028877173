import Vue from 'vue';
import Vuex from 'vuex';
import { mainPage } from './mainPage.module';
import { user } from './user.module';
import { layout } from './layout.module';
import { notifications } from './notifications.module'
import { food } from './food.module'
import { way } from './way.module'
import { pdf } from './pdf.module';
import { marketplace } from './marketplace.module'
import { merch } from './merch.module'
import { form } from './form.module'
import { cp } from './cp.module'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    food,
    form,
    layout,
    mainPage,
    notifications,
    pdf,
    user,
    way,
    marketplace,
    cp,
    merch,
  }
})

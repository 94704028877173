import baseApi from "@/helpers/baseApi";

const state = () => ({
  favourite: {
    requested: false,
    items: [],
  },
  menu: {
    requested: false,
    items: [],
  },
  slider: {
    requested: false,
    items: [],
  },
  is_onboarding: window.localStorage.getItem("onboarded") !== "true",
})

const mutations = {

  setMenuItems(state, payload){
    state.menu = { 
      ...state.menu, 
      items: payload 
    } 
  },

  setMenuRequested(state){
    state.menu = {
      ...state.menu,
      requested: true
    }
  },

  setFavouriteItems(state, payload){
    state.favourite = { 
      ...state.favourite, 
      items: payload
    } 
  },

  setFavouriteRequested(state){
    state.favourite = {
      ...state.favourite,
      requested: true
    }
  },

  'add_favourite'(state, payload){
    // TODO
    state.favourite = { 
      ...state.favourite, 
      items: [
        ...state.favourite.items,
        payload
      ]      
    } 
  },
  
  'remove_favourite'(state, id){
    state.favourite = { 
      ...state.favourite, 
      items: state.favourite.items.filter((fav) => {
        return fav.id != id
      })   
    } 
  },
  
  setSliderItems(state, payload){
    state.slider = { 
      ...state.slider, 
      items: payload 
    } 
  },

  setSliderRequested(state){
    state.slider = {
      ...state.slider,
      requested: true
    }
  },
  
  'set_is_onboarding'(state, value){
    state.is_onboarding = value;
  },

}

const actions = {
  
  initialize({ dispatch }){
    return Promise.allSettled([
      dispatch('getMenu'),
      dispatch('getFavourites'),
      dispatch('getSlider'),
      dispatch('notifications/get', undefined, { root: true }),
    ]);    
  },
  
  getMenu({ commit }){
    return baseApi('api/menu/navs/').then((res) => {
      if (res?.data){
        commit('setMenuItems', res.data);
      } else {
        throw Error('no menu items in response')
      }
    }).finally(() => {
      commit('setMenuRequested')
    })
  },

  getFavourites({ commit }){
    return baseApi('api/menu/favorites/').then((res) => {
      if (res.data){
        commit('setFavouriteItems', res.data);
      } else {
        throw Error('no favourite items in response')
      }
    }).finally(() => {
      commit('setFavouriteRequested')
    })
  },

  getSlider({ commit }){
    console.log(this);
    return baseApi('api/menu/banners/').then((res) => {
      if (res?.data){
        commit('setSliderItems', res.data);
      } else {
        throw Error('no banner items in response')
      }
    }).finally(() => {
      commit('setSliderRequested')
    })
  },


  async setOnboarded({ commit }){
    window.localStorage.setItem("onboarded", "true");
    commit('set_is_onboarding', false);
  },

  async setNotOnboarded({ commit }){
    window.localStorage.removeItem('onboarded')
    commit('set_is_onboarding', true);
  },
  
  addFavourite({ commit }, id){
    return baseApi.post(`api/menu/favorites/`, {menu_item: id}).then((res) => {
      if (res.data){
        commit('add_favourite', res.data)
      }
      // RES
      // id: 10
      // menu_item: 31
      // menu_title: "Timesheet"
      // user: 3
      // } else {
      //   throw Error('no favourite items in response')
      // }
    })
  },

  removeFavourite({ state, commit }, menu_item_id){
    let id = state.favourite.items.find((fav_item) => 
      (fav_item.menu_item == menu_item_id))?.id

    return baseApi.delete(`api/menu/favorites/${id}/`).then(() => {
      commit('remove_favourite', id)
    })
  },

}

const getters = {
  // MENU
  'is_requested': (state, getters, rootState) => {
    return (
      state.favourite.requested
      && state.slider.requested
      && state.menu.requested
      && rootState.notifications.requested
    )
  },
  'menu_parents': (state) => (
    state.menu.items
      .filter(el => el.parent === null)
      .filter(el => !el.burger)
      .sort((el1,el2) => el1.priority > el2.priority)
  ),
  'has_children': (state) => (id) => (
    state.menu.items.some(item => item.parent === Number(id))
  ),
  'menu_filtered_by': (state) => (keyword) => (
    state.menu.items.filter((item) => (
      item.title.toLowerCase().includes(keyword.toLowerCase())|| 
      item.subtitle.toLowerCase().includes(keyword.toLowerCase())
    ))
  ),
  'get_menu_children': (state) => (id) => (
    state.menu.items
      .filter(el => (el.parent === Number(id)))
      .filter(el => !el.burger)
  ),
  'get_menu_item_by_form': (state) => (form_name) => {
    return state.menu.items.find(el => (el.form_name === form_name))
  },
  'get_menu_item_by_id': (state) => (id) => {
    return state.menu.items.find(el => (el.id === Number(id)))
  },
  'get_menu_item_depth': (state, getters) => (id, depth=1) => {
    let cur = getters.get_menu_item_by_id(id);
    console.log(cur);
    if (cur.parent === null) {
      return depth
    } else {
      return getters.get_menu_item_depth(cur.parent, depth+1);
    }
  },
  // FAVOURITE
  'is_favourite': (state) => (id) => (
    state.favourite.requested && 
    state.favourite.items.some(item => item.menu_item === Number(id))
  ),
  'get_burger_menu_items': (state) => {
    return state.menu.items.filter(el => el.burger);
  },
}

export const mainPage = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
<template>
  <div class="burger-body-item">
    <div
      v-if="icon" 
      class="burger-body-item__icon"
      :class="{
        'burger-body-item__icon--rotating': iconRotate,
      }"
    >
      <img :src="icon" alt="">
    </div>
    <div class="burger-body-item__content">
      <p 
        class="small-medium"
        :style="disabled && 'color: #DDE1E5;'"  
      >{{ title }}</p>
      <p 
        v-if="subtitle"
        class="caption text-gray-v2"
        :style="disabled && 'color: #DDE1E5;'"  
      >{{ subtitle || '' }}</p>
    </div>
    <div 
      v-if="textRight"
      class="burger-body-item__content-right">
      <p class="caption" style="color: #8E8E93;">{{ textRight }}</p>
    </div>
    <div 
      v-if="rightArrow"
      class="burger-body-item__icon-right"
    >
      <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path opacity=".2" d="M1 11l5-5-5-5" stroke="#111114" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppBurgerBodyItem',
  props: ['icon', 'iconRotate', 'disabled', 'title', 'subtitle', 'rightArrow', 'textRight'], 
}
</script>

<style lang="scss">
.burger-body-item{
  display: flex;
  justify-content: space-between;
  align-items: center;
  // margin: 4px 0;
  padding: 16px;
  background: #FFFFFF;
  border-top: 1px solid #F9F9F9;
  &:last-child{
    border-radius: 0 0 18px 18px;
  }
  &__icon{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    margin-right: 16px;
    img{
      max-width: 100%;
    }
    &--rotating{
      animation: rotating 2s linear infinite;
    }
  }
  &__content{
    flex: 1 1 auto;
    margin: 0 16px 0 0;
    // p:empty{
    //   height: 1.25em;
    // }
  }
  &__content-right{
    margin: 0 16px 0 8px;
  }
  &__icon-right{
    display: flex;
    justify-content: center;
    align-items: center;
    // width: 24px;
    // height: 24px;
    img{
      max-width: 100%;
    }
  }
}
</style>
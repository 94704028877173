<template>
  <div
    style="height: 100%;"
  >
    <AppLoaderScreen
      v-if="!is_requested && !is_select_auth_form"
      :theme="theme"
      @authenticated="handleLoggedIn"
      @on410="navigateToRegForm"
    />
    <MarketLoaderScreen
      v-else-if="isMarket && isMarketLoaderVisible"
      @hideMarketLoader="isMarketLoaderVisible = false"
    />
    <MerchLoaderScreen
      v-else-if="isMerch && isMerchLoaderVisible"
      @hideMarketLoader="isMerchLoaderVisible = false"
    />
    <AppLayout
      v-else
      :hideNavs="is_select_auth_form && !is_requested"
    >
      <template
        v-slot:header
      >
        <AppBurgerHeader
          v-if="show_burger_content"
        />
        <router-view
          v-else
          name="header"
        ></router-view>
      </template>
      <template v-slot:main>
        <v-scroll-x-transition
          :hide-on-leave="true"
        >
          <AppBurgerBody
            v-if="show_burger_content"
          />
        </v-scroll-x-transition>
        <router-view
          v-show="!show_burger_content"
        />
        <AppOnboarding
          v-if="is_onboarding && (!is_select_auth_form && is_requested)"
          v-model="is_onboarding"
        />
      </template>
      <template
        v-slot:footer
      >
        <router-view
          v-show="!show_burger_content"
          name="footer"
        ></router-view>
        <FloatingNav
          v-if="showFloatingNav"
        />
      </template>
    </AppLayout>

  </div>
</template>
<script>
import AppBurgerBody from "@/components/App/AppBurgerBody";
import AppBurgerHeader from "@/components/App/AppBurgerHeader";
import AppLayout from "@/components/App/AppLayout";
import AppLoaderScreen from "@/components/App/AppLoaderScreen.vue";
import MarketLoaderScreen from "@/components/marketplace/market_loader.vue"
import MerchLoaderScreen from "@/components/merch/merch_loader.vue"
import AppOnboarding from "@/components/App/AppOnboarding";
import bridge from '@/helpers/bridge';
import FloatingNav from "@/components/FloatingNav";
import { mapGetters, mapState } from "vuex";
import app from '@/helpers/app';
import baseApi from "@/helpers/baseApi";
        
export default {
  name: "APP",
  components: {
    FloatingNav,
    AppBurgerBody,
    AppBurgerHeader,
    AppLayout,
    AppLoaderScreen,
    MarketLoaderScreen,
    MerchLoaderScreen,
    AppOnboarding,
    // SearchInput,
  },
  data(){
    return {
      header: {
        title: 'Smart ERG',
        back: false,
        search: true,
      },
      body: 'menu',
      showFloatingNav: process.env.VUE_APP_SHOW_FLOATING_MENU === 'true',
      theme: new URLSearchParams(window.location.search).get('theme'),
      isMarketLoaderVisible: true,
      isMerchLoaderVisible: true,
    }
  },
  methods: {

    tabActiveHandler(tabname){
      // if (process.env.VUE_APP_SHOW_DEV_FEATURES === 'true'){
      //   this.$store.dispatch('layout/alert', 'dev tabname: ' + tabname)
      // }
      if (tabname === 'MyERG' || tabname === 'myERG_test'){
        this.$store.dispatch('layout/hideBurgerContent');
        this.$router.replace({
          name: 'MainPage'
        })
      }
    },

    handleLoggedIn(){
      this.$store.dispatch('mainPage/initialize');
      // (async () => {
      //   await aituBridge.enableNotifications();
      // })();
    },

    navigateToRegForm(payload){ // on 410
      this.$store.dispatch('layout/setIsSelectAuthForm', true);
      this.$router.replace({
        name: 'FormPage',
        params: {
          form_data: payload,
        },
      })
    },

    headerMenuItemClickHandler(id){
      let { dispatch } = this.$store;
      if (id === 'person'){
        dispatch('layout/toggleBurgerContent');
      } else if (id === 'notifications'){
        dispatch('layout/hideBurgerContent');
        this.$router.replace({ name: 'NotificationsPage', })
      } else {
        dispatch('layout/alert', 'Неизвестный эллемент меню: ' + id);
      }
    },

  },
  computed: {
    is_onboarding: {
      get(){
        return this.$store.state.mainPage.is_onboarding
      },
      set(){
        this.$store.dispatch('mainPage/setOnboarded');
      }
    },
    ...mapGetters({
      unread_total: 'notifications/unread_total',
      is_requested: 'mainPage/is_requested'
    }),
    ...mapState("layout", [
      "language",
      "is_select_auth_form",
      "show_burger_content",
      "header_title",
    ]),
    show_header_menu_items(){
      return !this.is_select_auth_form && this.is_requested && !this.is_onboarding
    },
    isMarket() {
      return this.$route.path.startsWith('/s/market');
    },
    isMerch() {
      return this.$route.path.startsWith('/s/merch');
    }
  },

  watch: {

    "$store.state.layout.theme_color": (newColor, oldColor) => {
      if (newColor !== oldColor){
        document.querySelector('meta[name="theme-color"]').setAttribute('content',  newColor);
      }
    },

    is_requested(val){
      if (val){
        app.backNavigateQueue.init();
        bridge.setTabActiveHandler(this.tabActiveHandler.bind(this));
      }
    },

    show_header_menu_items(val){
      if (val){
        app.initHeaderMenuItems({
          notifications_count: this.unread_total,
          handler: this.headerMenuItemClickHandler
        })
      }
    },
    language: {
      immediate: true,
      handler(val){
        baseApi.defaults.headers['Accept-Language'] = val;
      }
    },
  },

}
</script>
<style lang="scss" specific>
@import "node_modules/normalize.css/normalize";
@import "src/styles/index.scss";
@import "src/styles/dashboard/";
</style>

import merchApi from "@/helpers/merchApi";

const state = () => ({
  api_url: process.env.VUE_APP_MERCH_HOST,
  marketUser: undefined,
  Merchtoken: '',
  limit: '',
  addressList: [],
  mvzList: [],
  permsList: [],
  statuses: undefined,
  cart: [],
  cancelQueue: [],
  ordersPaginationInfo: undefined,
  orders: [],
  orderById: undefined,
  approveOrdersPaginationInfo: undefined,
  approveOrders: [],
  approveOrder: undefined,
  acceptOrdersPaginationInfo: undefined,
  acceptOrders: [],
  acceptOrder: undefined,
  categories: [],
  product: undefined,
  productReviews: [],
  searchResult: [],
  queryParams: new URLSearchParams(),
  filteredProducts: undefined,

  loginError: undefined,
  cartError: undefined,
  orderError: undefined,
  categoryError: undefined,
});

const mutations = {

  // ERRORS
  setLoginError(state, payload) {
    state.loginError = payload;
  },
  setCartError(state, payload) {
    state.cartError = payload;
  },
  setOrderError(state, payload) {
    state.orderError = payload;
  },
  setCategoryError(state, payload) {
    state.categoryError = payload;
  },
  // ERRORS ^

  setUserInfo(state, payload) {
    state.marketUser = payload;
    state.Merchtoken = payload.token;
    if (payload.order_limit) {
      state.limit = parseFloat(payload.order_limit);
    } else state.limit = '';
    state.addressList = payload.addresses;
    state.mvzList = payload.mvz;
    state.permsList = payload.perms;
  },

  setOrderStatuses(state, payload) {
    state.statuses = payload;
  },

  setUserCart(state, payload) {
    state.cart = payload;
  },

  setOrdersPaginationInfo(state, payload) {
    state.ordersPaginationInfo = payload;
  },

  setUserOrders(state, payload) {
    state.orders = state.orders.concat(payload);
  },

  'clear-user-orders'(state) {
    state.ordersPaginationInfo = undefined;
    state.orders = [];
  },

  setOrderById(state, payload) {
    state.orderById = payload;
  },

  'clear-order-by-id'(state) {
    state.orderById = undefined;
  },

  setApproveOrdersPaginationInfo(state, payload) {
    state.approveOrdersPaginationInfo = payload;
  },

  setApproveOrders(state, payload) {
    state.approveOrders = state.approveOrders.concat(payload);
  },

  'clear-approve-orders'(state) {
    state.approveOrdersPaginationInfo = undefined;
    state.approveOrders = [];
  },

  setApproveOrder(state, payload) {
    state.approveOrder = payload;
  },

  'clear-approve-order'(state) {
    state.approveOrder = undefined;
  },

  setAcceptOrdersPaginationInfo(state, payload) {
    state.acceptOrdersPaginationInfo = payload;
  },

  setAcceptOrders(state, payload) {
    state.acceptOrders = state.acceptOrders.concat(payload);
  },

  'clear-accept-orders'(state) {
    state.acceptOrdersPaginationInfo = undefined;
    state.acceptOrders = [];
  },

  setAcceptOrder(state, payload) {
    state.acceptOrder = payload;
  },

  'clear-accept-order'(state) {
    state.acceptOrder = undefined;
  },

  setCategories(state, payload) {
    state.categories = payload;
  },

  setProduct(state, payload) {
    state.product = payload;
  },

  'clear-product'(state) {
    state.product = undefined;
  },

  setProductReviews(state, payload) {
    state.productReviews = payload;
  },

  'clear-product-reviews'(state) {
    state.productReviews = [];
  },

  setSearchResult(state, payload) {
    state.searchResult = payload;
  },

  'clear-search-result'(state) {
    state.searchResult = [];
  },

  'set-query-params'(state, params) {
    if (params?.length) {
      state.queryParams.delete(params[0].name);
      params.forEach(param => {
        state.queryParams.append(param.name, param.value);
      })
    } else {
      if (state.queryParams?.has(params.name)) {
        state.queryParams.set(params.name, params.value);
      } else {
        state.queryParams.append(params.name, params.value);
      }
    }
  },

  'delete-query-param'(state, param) {
    state.queryParams.delete(param);
  },

  'clear-query-params'(state) {
    state.queryParams = new URLSearchParams();
  },

  setFilteredProducts(state, payload) {
    if (state.filteredProducts) {
      state.filteredProducts = state.filteredProducts.concat(payload)
    } else {
      state.filteredProducts = payload;
    }
  },

  'clear-filtered-products'(state) {
    state.filteredProducts = undefined;
  },

  'add-to-cancel-queue'(state, { is_editing, lineId }) {
    if (is_editing) {
      let cancelingItem = state.cancelQueue.find(line => {
        return line === lineId
      });
      if (cancelingItem) {
        state.cancelQueue = state.cancelQueue.filter(line => {
          return cancelingItem !== line
        })
      } else {
        state.cancelQueue = [
          ...state.cancelQueue,
          lineId,
        ];
      }
    }
  },

  'add-all-to-cancel-queue'(state, { is_editing, lineIds }) {
    if (is_editing) {
      state.cancelQueue = lineIds;
    }
  },

  'clear-cancel-queue'(state) {
    state.cancelQueue = [];
  },
}

const actions = {

  getUserInfo({ commit }) {
    if (merchApi.defaults.headers['Merchauthorization']) {
      delete merchApi.defaults.headers['Merchauthorization'];
    }
    return merchApi.get('/merch/api/myerglogin/').then((res) => {
      if (res?.data) {
        commit('setUserInfo', res.data);
        merchApi.defaults.headers['Merchauthorization'] = ' Merchtoken ' + res.data.token;
      } else {
        throw Error('no user info in response')
      }
    }).catch((error) => {
      if (error) {
        commit('setLoginError', error);
      }
    })
  },

  getOrderStatuses({ commit }) {
    return merchApi.get('/merch/api/statusmap/').then((res) => {
      if (res?.data) {
        commit('setOrderStatuses', res.data);
      } else {
        throw Error('no user info in response')
      }
    })
  },

  getUserCart({ commit }) {
    return merchApi.get('/merch/api/basket/').then((res) => {
      if (res?.data) {
        commit('setUserCart', res.data);
      } else {
        throw Error('no user info in response')
      }
    }).catch((error) => {
      if (error) {
        commit('setCartError', error);
      }
    })
  },

  getUserOrders({ state, commit }) {
    let URL = '/merch/api/orders/';
    let page = state.ordersPaginationInfo ? `?page=${state.ordersPaginationInfo.next}` : '';
    return merchApi.get(`${URL}${page}`).then((res) => {
      if (res?.data) {
        commit('setUserOrders', res.data ?? []);
      } else {
        throw Error('no user info in response')
      }
    }).catch((error) => {
      if (error) {
        commit('setOrderError', error);
      }
    })
  },

  clearUserOrders({ commit }) {
    commit('clear-user-orders');
  },

  getOrderById({ commit }, orderId) {
    return merchApi.get(`/merch/api/orders/${orderId}/`).then((res) => {
      if (res?.data) {
        commit('setOrderById', res.data);
      } else {
        throw Error('no user info in response')
      }
    })
  },

  clearOrderById({ commit }) {
    commit('clear-order-by-id');
  },

  getApproveOrders({ commit }) {
    let URL = '/merch/api/approves/';
    let page = state.approveOrdersPaginationInfo ? `?page=${state.approveOrdersPaginationInfo.next}` : '';
    return merchApi.get(`${URL}${page}`).then((res) => {
      if (res?.data) {
        commit('setApproveOrdersPaginationInfo', res.data);
        commit('setApproveOrders', res.data.results);
      } else {
        throw Error('no user info in response')
      }
    })
  },

  clearApproveOrders({ commit }) {
    commit('clear-approve-orders');
  },

  getApproveOrder({ commit }, orderId) {
    return merchApi.get(`/merch/api/approves/${orderId}/`).then((res) => {
      if (res?.data) {
        commit('setApproveOrder', res.data);
      } else {
        throw Error('no user info in response')
      }
    })
  },

  clearApproveOrder({ commit }) {
    commit('clear-approve-order');
  },

  getAcceptOrders({ commit }) {
    let URL = '/merch/api/acceptorders/';
    let page = state.acceptOrdersPaginationInfo ? `?page=${state.acceptOrdersPaginationInfo.next}` : '';
    return merchApi.get(`${URL}${page}`).then((res) => {
      if (res?.data) {
        commit('setAcceptOrdersPaginationInfo', res.data);
        commit('setAcceptOrders', res.data.results);
        return res.data;
      } else {
        throw Error('no user info in response')
      }
    })
  },

  clearAcceptOrders({ commit }) {
    commit('clear-accept-orders');
  },

  getAcceptOrder({ commit }, orderNumber) {
    return merchApi.get(`/merch/api/acceptorder/${orderNumber}/`).then((res) => {
      if (res?.data) {
        commit('setAcceptOrder', res.data);
        return res.data;
      } else {
        throw Error('no user info in response')
      }
    })
  },

  clearAcceptOrder({ commit }) {
    commit('clear-accept-order');
  },

  acceptOrderedLines({}, { orderNumber, linesArray }) { // eslint-disable-line
    return merchApi.patch(`/merch/api/acceptorder/${orderNumber}/`, { "accepted_lines": linesArray });
  },

  rejectOrderedLines({}, { orderNumber, linesArray }) { // eslint-disable-line
    return merchApi.patch(`/merch/api/acceptorder/${orderNumber}/`, { "rejected_lines": linesArray });
  },

  approveOrDeclineOrder({}, { orderId, approve, comment }) { // eslint-disable-line
    let payload = { approve, comment };
    return merchApi.patch(`/merch/api/approves/${orderId}/`, payload);
  },

  getCategories({ commit }) {
    return merchApi.get('/merch/api/categories/').then((res) => {
      if (res?.data) {
        commit('setCategories', res.data);
      } else {
        throw Error('no user info in response')
      }
    }).catch((error) => {
      if (error) {
        commit('setCategoryError', error);
      }
    })
  },

  getProductsByFilter({ state, commit }, categoryId) {
    let queryLength = state.queryParams?.toString().length;
    let URL = `/merch/api/products/?categories=${categoryId}`;
    let query = queryLength ? `${state.queryParams}` : '';
    return merchApi.get(`${URL}&${query}`).then((res) => {
      if (res?.data) {
        commit('setFilteredProducts', res.data);
      } else {
        throw Error('no user info in response')
      }
    })
  },

  clearProductsByFilter({ commit }) {
    commit('clear-filtered-products');
  },

  getProduct({ commit }, payload) {
    return merchApi.get(`/merch/api/products/${payload}/`).then((res) => {
      if (res?.data) {
        commit('setProduct', res.data);
      } else {
        throw Error('no user info in response')
      }
    })
  },

  clearProduct({ commit }) {
    commit('clear-product');
  },

  getProductReviews({ commit }, payload) {
    return merchApi.get(`/merch/api/products/${payload}/reviews/`).then((res) => {
      if (res?.data) {
        commit('setProductReviews', res.data);
      } else {
        throw Error('no user info in response')
      }
    })
  },

  clearProductReviews({ commit }) {
    commit('clear-product-reviews');
  },

  searchForProduct({ commit }, payload) {
    return merchApi.get(`/merch/api/products/search/${payload}/`).then((res) => {
      if (res?.data) {
        commit('setSearchResult', res.data);
      } else {
        throw Error('no user info in response')
      }
    })
  },

  clearSearchResult({ commit }) {
    commit('clear-search-result');
  },

  setQueryParams({ commit }, params) {
    commit('set-query-params', params);
  },

  deleteQueryParam({ commit }, param) {
    commit('delete-query-param', param);
  },

  clearQueryParams({ commit }) {
    commit('clear-query-params');
  },

  addToCart({ state, dispatch, getters }, { id, quantity = 1 }) {
    if (getters.in_cart_qty_by_id(id) == 0) {
      dispatch("addProductToCart", { product: id, quantity });
    } else {
      dispatch("editProductInCart", {
        productId: id,
        basket: state.cart.id,
        quantity: getters.in_cart_qty_by_id(id) + quantity
      });
    }
  },

  removeFromCart({ state, dispatch, getters }, id) {
    if (getters.in_cart_qty_by_id(id) == 1) {
      dispatch("deleteProductFromCart", { lineId: getters.line_id_by_product_id(id) });
    } else if (getters.in_cart_qty_by_id(id) > 1) {
      dispatch("editProductInCart", {
        productId: id,
        basket: state.cart.id,
        quantity: getters.in_cart_qty_by_id(id) - 1
      });
    }
  },

  addProductToCart({ dispatch }, payload) {
    return merchApi.post(`/merch/api/basket/add-product/`, payload).then((res) => {
      if (res?.data) {
        dispatch("getUserCart");
      } else {
        throw Error('no user info in response')
      }
    })
  },

  editProductInCart({ dispatch, getters }, { productId, basket, quantity }) {
    let lineId = getters.line_id_by_product_id(productId);
    return merchApi.put(`/merch/api/basket/lines/${lineId}/`, { basket, quantity }).then((res) => {
      if (res?.data) {
        dispatch("getUserCart");
      } else {
        throw Error('no user info in response')
      }
    })
  },

  deleteProductFromCart({ dispatch }, { lineId }) {
    return merchApi.delete(`/merch/api/basket/lines/${lineId}/`).then(() => {
      dispatch("getUserCart");
    })
  },

  checkoutOrder({}, payload) { // eslint-disable-line
    return merchApi.post(`/merch/api/checkout/`, payload).then((res) => {
      if (res?.data) {
        return res.data;
      } else {
        throw Error('no user info in response')
      }
    })
  },

  sendFeedback({}, { productId, feedbackData }) { // eslint-disable-line
    const url = `/merch/api/products/${productId}/reviews/`;
    if (feedbackData.body) {
      return merchApi.post(url, feedbackData).then((res) => {
        if (res?.data) {
          return res.data;
        } else {
          throw Error('no user info in response')
        }
      })
    } else {
      const config = { headers: { 'Content-Type': 'multipart/form-data' } };
      return merchApi.post(url, feedbackData, config).then((res) => {
        if (res?.data) {
          return res.data;
        } else {
          throw Error('no user info in response')
        }
      })
    }
  },

  addToCancelQueue({ commit }, { is_editing, lineId }) {
    commit('add-to-cancel-queue', { is_editing, lineId });
  },

  addAllToCancelQueue({ state, commit }, { is_editing, lineIds }) {
    if (state.cancelQueue.length === lineIds.length) {
      commit('clear-cancel-queue');
    } else {
      commit('add-all-to-cancel-queue', { is_editing, lineIds });
    }
  },

  clearCancelQueue({ commit }) {
    commit('clear-cancel-queue');
  },

  cancelOrderLines({ state, dispatch }, orderId) {
    return merchApi.patch(`/merch/api/orders/${orderId}/`, { cancelled_lines: state.cancelQueue }).then((res) => {
      if (res?.data) {
        dispatch("clearCancelQueue");
        return res.data;
      } else {
        throw Error('no user info in response')
      }
    })
  },
}

const getters = {
  // Category getters
  'all_categories': (state) => {
    return state.categories
  },

  // Cart getters
  'cart_price_total': (state) => {
    return state.cart.total_excl_tax;
  },

  'cart_qty_total': (state) => {
    return state.cart.lines?.reduce((acc, cur) => {
      return acc + cur.quantity || 0;
    }, 0)
  },

  'in_cart_qty_by_id': (state) => (id) => {
    return state.cart.lines?.find(item => item.product.id === id)?.quantity || 0;
  },

  'line_id_by_product_id': (state) => (id) => {
    return state.cart.lines?.find(item => item.product.id === id)?.id || 0;
  },

  // Permission getters
  'user__can_order': (state) => {
    return state.permsList?.includes('user.can_order');
  },
  'order__can_approve': (state) => {
    return state.permsList?.includes('order.can_approve');
  },
  'order__can_accept': (state) => {
    return state.permsList?.includes('order.can_accept');
  },

}

export const merch = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

<template>
  <div class="layout">
    <template 
      v-if="!hideNavs"
    >
      <slot name="header"></slot>
    </template>
    <!-- height spacer on header resize -->
    <div 
      v-if="!hideNavs"
      :style="{marginTop: header_height + 'px'}"
    ></div>
    <!-- spacer -->
    <v-snackbar
      ref="popup"
      :timeout="2500"
      :value="app_error"
      centered
      style="z-index: 17;"
    >
      {{ app_error }}
    </v-snackbar>
    <slot name="main"></slot>
    <template
      v-if="!hideNavs"
    >
      <slot name="footer"></slot>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";
import bridge from "@/helpers/bridge";

export default {
  name: "AppLayout",
  props: {
    hideNavs: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    ...mapState('layout', [
      'app_error', 
      'theme_color', 
      'header_height',
      'header_title'
    ])
  },
  watch: {
    header_title(val){
      bridge.setTitle(val || this.$translate('title_back'));
    }
  },
  mounted () {
    // Added this watcher, so that error is reset to empty string
    // why? because if error is the same string, the popup wont trigger
    this.$watch(
      () => {
        return this.$refs.popup.isActive
      },
      (val) => {
        if (val === false){
          this.$store.dispatch('layout/alert','')
        }
      }
    )
  },
}
</script>
<style lang="scss">
.layout{
  height: 100%;
  // width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .layout__header{
    padding: 6px 16px;
    background-color: #FFFFFF;    
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 2;
    &--gray{
      background-color: #F9F9F9;
    }
  }
  .layout__body{
    // #E5E5E5
    background-color: #F9F9F9;
    padding-top: 8px;
    flex-basis: auto;
    flex-grow: 1;
    min-width: 0;
    // Note:
    // if footer or header height will change dynamicly, the padding of body wont.
    // add event listeners to their heights and change it manually.
    @supports(padding: max(0px)) {      // check if ios device 
      padding-bottom: calc(16px + env(safe-area-inset-bottom, 0px));
    }
    // use this one inside of views
    &--main{
      background-color: #F3F3F3;
      padding-top: 0;
    }
    &--white{
      background-color: #FFF;
    }
    &--notifications{
      padding-top: 0;
    }
    &--way{
      padding-left: 16px;
      padding-right: 16px;
      background-color: #FFF;
      // overflow-x: hidden;
    }
    &--events{
      padding-left: 16px;
      padding-right: 16px;
      background-color: #FFF;
      // overflow-x: hidden;
    }
    &--error-placeholder{
      display: flex;
      flex-direction: column;
    }
    &--centered{
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-bottom: 128px;
    }
  }
}
</style>
import bridge from "@/helpers/bridge";
import store from "@/store";

export const backNavigateQueue = {
  handlerFns: [],
  menuActive: false,
  /**
  * Set new back 
  * @param fn function to exec on 'back'
  **/
  set(fn){
    console.log('backNavigateQueue.set');
    if (typeof fn !== 'function') throw new Error('handler should be a function, got ' + typeof fn);
    if (!this.handlerFns.length){
      window.androidHandleBack = this.exec;
      bridge.setCustomBackArrowVisible(true)
    }
    this.handlerFns = [ fn ];
  },
  /**
  * Add back navigation which will be poped after execution 
  * @param fn function to exec on 'back'
  **/
  push(fn){
    console.log('backNavigateQueue.push')
    if (typeof fn !== 'function') throw new Error('handler should be a function, got ' + typeof fn);
    if (!this.handlerFns.length){
      bridge.setCustomBackArrowVisible(true);
    }
    this.handlerFns.push(fn);
  },
  /**
  * Executes last handler fn and removes it 
  */ 
  exec(){
    console.log('backNavigateQueue.exec');
    let menuActive = store.getters['layout/show_burger_content'];
    if (menuActive){
      store.dispatch('layout/hideBurgerContent');
      if (!this.handlerFns.length){
        bridge.setCustomBackArrowVisible(false);
      }
    } else if (this.handlerFns.length){
      this.handlerFns[this.handlerFns.length-1]();
    } else {
      console.log('No func to exec')
    }
  },
  pop(){
    console.log('backNavigateQueue.pop');
    this.handlerFns.pop();
    if (!this.handlerFns.length){
      bridge.setCustomBackArrowVisible(false);
    }
  },
  clear(){
    console.log('backNavigateQueue.clear')
    this.handlerFns = [];
    delete window.androidHandleBack;
    bridge.setCustomBackArrowVisible(false);
  },
  async init(){
    console.log('backNavigateQueue.init')
    // let customBack = await bridge.getCustomBackArrowMode()
    // console.log('customBack', customBack);
    // if (!customBack){
    await bridge.setCustomBackArrowMode(true);
    await bridge.setCustomBackArrowOnClickHandler(this.exec.bind(this));
    // }
  }
}

const getMenuItems = (notifications_count) => {
  return [
    {
      id:  "notifications",
      icon: 'Notifications',
      ...(notifications_count ? { badge: notifications_count+'' } : {})
    },
    {
      id: "person",
      icon: 'Person',
    }
  ]
}

export const setHeaderMenuItems = ({ notifications_count }) => {
  return bridge.setHeaderMenuItems(getMenuItems(notifications_count))
}

export const initHeaderMenuItems = ({ notifications_count, handler}) => {
  return bridge.setHeaderMenuItems(getMenuItems(notifications_count)).then(() => {
    console.log('setting headerMenuItemClickHandler');
    bridge.setHeaderMenuItemClickHandler(handler);
  })
}

export default {
  backNavigateQueue,
  setHeaderMenuItems,
  initHeaderMenuItems,
}
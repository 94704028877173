import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index';
// import { backNavigateQueue } from '@/helpers/app'
Vue.use(VueRouter)

const DevPage = () => import(/* webpackChunkName: "dev" */ '@/views/dev.vue')
const UnderMaintenancePage = () => import(/* webpackChunkName: "dev" */ '@/views/marketplace/502.vue')
// mainPage
const Main = () => import(/* webpackChunkName: "mainPage" */ '@/views/main.vue');
const MainHeader = () => import(/* webpackChunkName: "mainPage" */ '@/views/header/main.vue');
// navigationChild
const NavigationChild = () => import(/* webpackChunkName: "navigationChild" */ '@/views/navigationChild.vue');
const NavigationChildFooter = () => import(/* webpackChunkName: "navigationChild" */ '@/views/navigationChildFooter.vue');
const Form = () => import(/* webpackChunkName: "navigationChild" */ '@/views/form.vue');
const ProfileHeader = () => import(/* webpackChunkName: "navigationChild" */ '@/views/header/profile.vue');
// notifications
const Notifications = () => import(/* webpackChunkName: "notifications" */ '@/views/notifications/index.vue');
const NotificationsSettings = () => import(/* webpackChunkName: "notifications" */ '@/views/notifications/settings.vue');
// food
const FoodIndex = () => import(/* webpackChunkName: "food" */ '@/views/food/index.vue');
const FoodHeader = () => import(/* webpackChunkName: "food" */ '@/views/food/header.vue');
const FoodFooter = () => import(/* webpackChunkName: "food" */ '@/views/food/footer.vue');
const FoodHistoryIndex = () => import(/* webpackChunkName: "food" */ '@/views/food/history/index.vue');
const FoodHistoryById = () => import(/* webpackChunkName: "food" */ '@/views/food/history/_id/index.vue');
const FoodHistoryByIdHeader = () => import(/* webpackChunkName: "food" */ '@/views/food/history/_id/header.vue');
const FoodQr = () => import(/* webpackChunkName: "food" */ '@/views/food/qr/index.vue');
const FoodQrHeader = () => import(/* webpackChunkName: "food" */ '@/views/food/qr/header.vue');
const FoodOrder = () => import(/* webpackChunkName: "food" */ '@/views/food/order.vue');
// calendar
const Calendar = () => import(/* webpackChunkName: "calendar" */ '@/views/my_calendar_form/index.vue');
const CaledarFooter = () => import(/* webpackChunkName: "calendar" */ '@/views/my_calendar_form/footer.vue');
// way
const Way = () => import(/* webpackChunkName: "way" */ '@/views/way/index.vue');
const WayByCategoryById = () => import(/* webpackChunkName: "way" */ '@/views/way/_category/_id.vue');
// const WayArticleHeader = () => import(/* webpackChunkName: "way" */ '@/views/way/:category/header.vue');
const WayArticleFooter = () => import(/* webpackChunkName: "way" */ '@/views/way/_category/footer.vue');
// event
const Events = () => import(/* webpackChunkName: "way" */ '@/views/events/index.vue');
// pdf-reader
const PdfReader = () => import(/* webpackChunkName: "pdf" */ '@/views/pdf/index.vue');
const PdfReaderHeader = () => import(/* webpackChunkName: "pdf" */ '@/views/pdf/header.vue');
// survey
const SurveyById = () => import(/* webpackChunkName: "survey" */ '@/views/survey/_id.vue');
// dashboard
const Divisions = () => import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/Divisions.vue')
const Projects = () => import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/Projects.vue')
const Programs = () => import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/Programs.vue')
const ProjectsPage = () => import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/ProjectsPage.vue')
const Passport = () => import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/Passport.vue')
const VideoHikvision = () => import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/VideoHikvision.vue')
const Image = () => import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/Image.vue')
const Staff = () => import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/Staff.vue')
const Budget = () => import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/Budget.vue')
const BudgetDetailed = () => import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/BudgetDetailed.vue')
const Engineering = () => import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/Engineering.vue')
const Supply = () => import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/Supply.vue')
const SupplyFolder = () => import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/SupplyFolder.vue')
const Building = () => import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/Building.vue')
const BuildingFolder = () => import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/BuildingFolder.vue')
const BuildingBE = () => import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/BuildingBE.vue')
const Schedule = () => import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/Schedule.vue')
const EngineeringDetailed = () => import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/EngineeringDetailed.vue')
//my-video
const MyVideo = () => import(/* webpackChunkName: "myvideo" */ '@/views/my_video');
const routes = [
  {
    path: '/',
    name: 'MainPage',
    components: {
      default: Main,
      header: MainHeader,
    }
  },
  {
    path: '/m/:menu_id',
    name: 'NavigationItemChild',
    components: {
      default: NavigationChild,
      footer: NavigationChildFooter,
    },
    props: {
      default: true,
      footer: true,
    }
  },
  {
    path: '/m/:menu_id/s/my_calendar_form',
    name: 'NavigationItemCalendarFormPage',
    components: {
      default: Calendar,
      footer: CaledarFooter,
    },
    props: {
      default: route => ({
        activeDate: route.query.date,
        backToName: route.query.from,
      }),
      footer: true,
    }
  },
  {
    path: '/f/myvideo',
    name: 'MyVideo',
    components: {
      default: MyVideo,
    }
  },
  {
    path: '/m/:menu_id/f/:form_name',
    name: 'NavigationItemFormPage',
    components: {
      default: Form,
    },
    props: {
      default: true,
    },
    beforeEnter(to, from, next){
      let { form_name } = to.params;
      if (form_name === 'erg_way_form') {
        next({
          replace: true,
          name: 'Way',
        })
      } else if (form_name === 'my_calendar_form') {
        next({
          replace: true,
          name: 'NavigationItemCalendarFormPage',
          params: {
            menu_id: to.params.menu_id
          }
        })
      } else if (form_name === 'test_survey_form' || form_name === 'survey_form') {
        next({
          replace: true,
          name: 'SurveyByIdPage',
          params: {
            id: to.params.menu_id
          },
          query: {
            ...to.query,
          }
        })
      } else if (form_name === 'market') {
        next({
          replace: true,
          name: 'Marketplace',
        })
      } else next()
    },
  },
  {
    path: '/s/erg_way_form',
    name: 'Way',
    components: {
      default: Way,
    },
    props: {
      default: route => ({
        ...route.query,
        ...route.params,
        menu_id: store.getters['mainPage/get_menu_item_by_form']('erg_way_form')?.id,
      }),
    }
  },
  {
    path: '/s/erg_way_form/:category',
    name: 'WayCategory',
    components: {
      default: Way,
    },
    props: {
      default: route => ({
        ...route.query,
        ...route.params,
        menu_id: store.getters['mainPage/get_menu_item_by_form']('erg_way_form')?.id,
      }),
    },
  },
  {
    path: '/s/erg_way_form/:category/:id',
    name: 'WayCategoryArticleById',
    components: {
      default: WayByCategoryById,
      // header: WayArticleHeader,
      footer: WayArticleFooter,
    },
    props: {
      default: route => ({
        ...route.query,
        ...route.params,
        menu_id: store.getters['mainPage/get_menu_item_by_form']('erg_way_form')?.id,
      }),
      footer: true,
    },
  },
  {
    path: '/f/url',
    name: 'FormPageByUrl',
    components: {
      default: Form,
    },
    props: {
      default: (route) => {
        console.log(route?.query);
        // First `if` is edge case
        if (route?.query?.state){
          let urlParam = new URLSearchParams(route?.query?.state);
          return {
            is_edge_case: true,
            form_url: urlParam.get('form_url'),
            form_url_data: {
              ...route.query,
            }
          }
        } else if (route?.query?.method){
          // deconstruct query parameters
          const {
            form_url,
            method,
            ...form_url_data
          } = route?.query;
          return {
            form_url: form_url,
            form_url_method: method,
            form_url_data,
          }
        }
        return {
          form_url: route?.query?.form_url,
          backToName: route.query.from,
        }
      },
    },
  },
  {
    path: '/f/events',
    name: 'ServiceEvents',
    components: {
      default: Events,
    },
    props: {
      default: route => ({
        type: route.query.type,
        page: route.query.page,
      }),
    },
  },
  {
    path: '/s/my_calendar_form',
    name: 'CalendarFormPage',
    components: {
      default: Calendar,
      footer: CaledarFooter,
    },
    props: {
      default: route => ({
        activeDate: route.query.date,
        backToName: route.query.from,
        ...route.params
      }),
    },
  },
  {
    path: '/s/market',
    name: 'Marketplace',
    components: {
      default: () => import(/* webpackChunkName: "marketplace" */ '@/views/marketplace/index.vue'),
    },
  },
  {
    path: '/s/market/select-city',
    name: 'MarketplaceSelectCity',
    components: {
      default: () => import(/* webpackChunkName: "marketplace" */ '@/views/marketplace/select_city.vue'),
    },
  },
  {
    path: '/s/market/categories',
    name: 'Market',
    components: {
      default: () => import(/* webpackChunkName: "marketplace" */ '@/views/marketplace/categories.vue'),
      footer: () => import(/* webpackChunkName: "marketplace" */ '@/views/marketplace/footer.vue'),
    },
  },
  {
    path: '/s/market/subcategory/:subcategoryId',
    name: 'Subcategory',
    components: {
      default: () => import(/* webpackChunkName: "marketplace" */ '@/views/marketplace/subcategories.vue'),
      footer: () => import(/* webpackChunkName: "marketplace" */ '@/views/marketplace/footer.vue'),
    },
    props: {
      default: true,
    },
  },
  {
    path: '/s/market/sub_subcategory/:sub_subcategoryId',
    name: 'Sub-subcategory',
    components: {
      default: () => import(/* webpackChunkName: "marketplace" */ '@/views/marketplace/sub_subcategories.vue'),
      footer: () => import(/* webpackChunkName: "marketplace" */ '@/views/marketplace/footer.vue'),
    },
    props: {
      default: true,
    },
  },
  {
    path: '/s/market/sub_sub_subcategory/:sub_sub_subcategoryId',
    name: 'Sub-sub-subcategory',
    components: {
      default: () => import(/* webpackChunkName: "marketplace" */ '@/views/marketplace/sub_sub_subcategories.vue'),
      footer: () => import(/* webpackChunkName: "marketplace" */ '@/views/marketplace/footer.vue'),
    },
    props: {
      default: true,
    },
  },
  {
    path: '/s/market/list-of-products/:categoryId',
    name: 'List of products',
    components: {
      default: () => import(/* webpackChunkName: "marketplace" */ '@/views/marketplace/list_of_products.vue'),
      footer: () => import(/* webpackChunkName: "marketplace" */ '@/views/marketplace/footer.vue'),
    },
    props: {
      default: true,
    },
  },
  {
    path: '/s/market/products/:productId',
    name: 'ProductById',
    components: {
      default: () => import(/* webpackChunkName: "marketplace" */ '@/views/marketplace/product.vue'),
      footer: () => import(/* webpackChunkName: "marketplace" */ '@/views/marketplace/footer.vue'),
    },
    props: {
      default: true,
    },
  },
  {
    path: '/s/market/cart',
    name: 'Cart',
    components: {
      default: () => import(/* webpackChunkName: "marketplace" */ '@/views/marketplace/cart.vue'),
      footer: () => import(/* webpackChunkName: "marketplace" */ '@/views/marketplace/footer.vue'),
    },
  },
  {
    path: '/s/market/orders',
    name: 'Orders',
    components: {
      default: () => import(/* webpackChunkName: "marketplace" */ '@/views/marketplace/orders.vue'),
      footer: () => import(/* webpackChunkName: "marketplace" */ '@/views/marketplace/footer.vue'),
    },
    props: {
      default: true,
    },
  },
  {
    path: '/s/market/orders/:id',
    name: 'OrderById',
    components: {
      default: () => import(/* webpackChunkName: "marketplace" */ '@/views/marketplace/order_by_id.vue'),
      footer: () => import(/* webpackChunkName: "marketplace" */ '@/views/marketplace/footer.vue'),
    },
    props: {
      default: true,
    },
  },
  {
    path: '/s/market/orders/approve-orders',
    name: 'ApproveOrders',
    components: {
      default: () => import(/* webpackChunkName: "marketplace" */ '@/views/marketplace/approve_orders.vue'),
      footer: () => import(/* webpackChunkName: "marketplace" */ '@/views/marketplace/footer.vue'),
    },
    props: {
      default: true,
    },
  },
  {
    path: '/s/market/orders/approve-orders/:id',
    name: 'ApproveById',
    components: {
      default: () => import(/* webpackChunkName: "marketplace" */ '@/views/marketplace/approve_by_id.vue'),
      footer: () => import(/* webpackChunkName: "marketplace" */ '@/views/marketplace/footer.vue'),
    },
    props: {
      default: true,
    },
  },
  {
    path: '/s/market/orders/accept-orders',
    name: 'AcceptOrders',
    components: {
      default: () => import(/* webpackChunkName: "marketplace" */ '@/views/marketplace/accept_orders.vue'),
      footer: () => import(/* webpackChunkName: "marketplace" */ '@/views/marketplace/footer.vue'),
    },
    props: {
      default: true,
    },
  },
  {
    path: '/s/market/orders/accept-order/:number',
    name: 'AcceptByNumber',
    components: {
      default: () => import(/* webpackChunkName: "marketplace" */ '@/views/marketplace/accept_by_id.vue'),
      footer: () => import(/* webpackChunkName: "marketplace" */ '@/views/marketplace/footer.vue'),
    },
    props: {
      default: true,
    },
  },
  {
    path: '/s/market/support',
    name: 'Support',
    components: {
      default: () => import(/* webpackChunkName: "marketplace" */ '@/views/marketplace/support.vue'),
      footer: () => import(/* webpackChunkName: "marketplace" */ '@/views/marketplace/footer.vue'),
    },
  },
  {
    path: '/s/market/under_maintenance',
    name: 'MarketUnderMaintenancePage',
    components: {
      default: UnderMaintenancePage,
      footer: () => import(/* webpackChunkName: "marketplace" */ '@/views/marketplace/footer.vue'),
    }
  },
  {
    path: '/s/market/discounts',
    name: 'Discounts',
    components: {
      default: () => import(/* webpackChunkName: "marketplace" */ '@/views/marketplace/discounts.vue'),
    }
  },
  { path: '/s/merch', redirect: '/s/merch/categories' },
  {
    path: '/s/merch/categories',
    name: 'Merch',
    components: {
      default: () => import(/* webpackChunkName: "merch" */ '@/views/merch/categories.vue'),
      footer: () => import(/* webpackChunkName: "merch" */ '@/views/merch/footer.vue'),
    },
  },
  {
    path: '/s/merch/list-of-products/:categoryId',
    name: 'Merch List of products',
    components: {
      default: () => import(/* webpackChunkName: "merch" */ '@/views/merch/list_of_products.vue'),
      footer: () => import(/* webpackChunkName: "merch" */ '@/views/merch/footer.vue'),
    },
    props: {
      default: true,
    },
  },
  {
    path: '/s/merch/products/:productId',
    name: 'MerchProductById',
    components: {
      default: () => import(/* webpackChunkName: "merch" */ '@/views/merch/product.vue'),
      footer: () => import(/* webpackChunkName: "merch" */ '@/views/merch/footer.vue'),
    },
    props: {
      default: true,
    },
  },
  {
    path: '/s/merch/cart',
    name: 'MerchCart',
    components: {
      default: () => import(/* webpackChunkName: "merch" */ '@/views/merch/cart.vue'),
      footer: () => import(/* webpackChunkName: "merch" */ '@/views/merch/footer.vue'),
    },
  },
  {
    path: '/s/merch/orders',
    name: 'MerchOrders',
    components: {
      default: () => import(/* webpackChunkName: "merch" */ '@/views/merch/orders.vue'),
      footer: () => import(/* webpackChunkName: "merch" */ '@/views/merch/footer.vue'),
    },
    props: {
      default: true,
    },
  },
  {
    path: '/s/merch/orders/:id',
    name: 'MerchOrderById',
    components: {
      default: () => import(/* webpackChunkName: "merch" */ '@/views/merch/order_by_id.vue'),
      footer: () => import(/* webpackChunkName: "merch" */ '@/views/merch/footer.vue'),
    },
    props: {
      default: true,
    },
  },
  {
    path: '/s/merch/support',
    name: 'MerchSupport',
    components: {
      default: () => import(/* webpackChunkName: "merch" */ '@/views/merch/support.vue'),
      footer: () => import(/* webpackChunkName: "merch" */ '@/views/merch/footer.vue'),
    },
  },
  {
    path: '/s/merch/under_maintenance',
    name: 'MerchUnderMaintenancePage',
    components: {
      default: UnderMaintenancePage,
      footer: () => import(/* webpackChunkName: "merch" */ '@/views/merch/footer.vue'),
    }
  },
  {
    path: '/f/erg_cp_dashboard',
    name: 'Divisions',
    components: {
      default: Divisions
    }
  },
  {
    path: '/f/erg_cp_dashboard/list/:divisionId',
    name: 'Projects',
    components: {
      default: Projects
    }
  },
  {
    path: '/f/erg_cp_dashboard/program/:programId',
    name: 'Programs',
    components: {
      default: Programs
    }
  },
  {
    path: '/f/erg_cp_dashboard/project/:projectId',
    name: 'ProjectsPage',
    components: {
      default: ProjectsPage
    }
  },
  {
    path: '/f/erg_cp_dashboard/passport/:projectId',
    name: 'Passport',
    components: {
      default: Passport
    }
  },
  {
    path: '/f/erg_cp_dashboard/video-hikvision/:projectId',
    name: 'VideoHikvision',
    components: {
      default: VideoHikvision
    }
  },
  {
    path: '/f/erg_cp_dashboard/image/',
    name: 'Image',
    components: {
      default: Image
    }
  },
  {
    path: '/f/erg_cp_dashboard/staff/:projectId',
    name: 'Staff',
    components: {
      default: Staff
    }
  },
  {
    path: '/f/erg_cp_dashboard/budget/:projectId',
    name: 'Budget',
    components: {
      default: Budget
    }
  },
  {
    path: '/f/erg_cp_dashboard/budget/:projectId/:paramType/:paramTitle',
    name: 'BudgetDetailed',
    components: {
      default: BudgetDetailed
    }
  },
  {
    path: '/f/erg_cp_dashboard/engineering/:projectId',
    name: 'Engineering',
    components: {
      default: Engineering
    }
  },
  {
    path: '/f/erg_cp_dashboard/engineering/:projectId/mdr',
    name: 'EngineeringDetailed',
    components: {
      default: EngineeringDetailed
    }
  },
  {
    path: '/f/erg_cp_dashboard/supply/:projectId',
    name: 'Supply',
    components: {
      default: Supply
    }
  },
  {
    path: '/f/erg_cp_dashboard/supply/folder/:projectId/:groupId',
    name: 'SupplyFolder',
    components: {
      default: SupplyFolder
    }
  },
  {
    path: '/f/erg_cp_dashboard/building/:projectId',
    name: 'Building',
    components: {
      default: Building
    }
  },
  {
    path: '/f/erg_cp_dashboard/building/:projectId/folder/:groupId',
    name: 'BuildingFolder',
    components: {
      default: BuildingFolder
    }
  },
  {
    path: '/f/erg_cp_dashboard/building/:projectId/be',
    name: 'BuildingBE',
    components: {
      default: BuildingBE
    }
  },
  {
    path: '/f/erg_cp_dashboard/schedule/:projectId',
    name: 'Schedule',
    components: {
      default: Schedule
    }
  },
  {
    path: '/f/:form_name',
    name: 'FormPage',
    components: {
      default: Form,
    },
    props: {
      default: route => ({
        backToName: route.query.from,
        ...route.params
      }),
    },
    beforeEnter(to, from, next){
      let { form_name } = to.params;
      if (to.params.form_name === 'erg_way_form') {
        next({
          replace: true,
          name: 'Way'
        })
      } else if (to.params.form_name === 'my_calendar_form') {
        next({
          replace: true,
          name: 'CalendarFormPage'
        })
      } else if (form_name === 'test_survey_form' || form_name === 'survey_form') {
        next({
          replace: true,
          name: 'SurveyByIdPage',
          params: {
            id: to.query.survey_id
          },
          query: {
            is_anonymous: to.query.is_anonymous,
          }
        })
      } else if (form_name === 'market') {
        next({
          replace: true,
          name: 'Marketplace',
        })
      } else next()
    },
  },
  {
    path: '/p',
    name: 'ProfilePage',
    components: {
      default: Form,
      header: ProfileHeader,
    },
    props: {
      default: () => ({
        form_name: 'profile_form',
      }),
    },
  },
  {
    path: '/n',
    name: 'NotificationsPage',
    components: {
      default: Notifications,
    },
  },
  {
    path: '/n/settings',
    name: 'NotificationsSettingsPage',
    components: {
      default: NotificationsSettings,
    },
  },
  {
    path: '/food',
    name: 'FoodPage',
    components: {
      default: FoodIndex,
      header: FoodHeader,
      footer: FoodFooter,
    },
  },
  {
    path: '/food/history',
    name: 'FoodHistoryPage',
    components: {
      default: FoodHistoryIndex,
      header: FoodHeader,
    },
  },
  {
    path: '/food/history/:id',
    name: 'FoodHistoryByIdPage',
    components: {
      default: FoodHistoryById,
      header: FoodHistoryByIdHeader,
    },
  },
  {
    path: '/food/qr',
    name: 'FoodQrPage',
    components: {
      default: FoodQr,
      header: FoodQrHeader,
    },
  },
  {
    path: '/food/order',
    name: 'FoodOrderPage',
    components: {
      default: FoodOrder,
      header: FoodHeader,
    },
  },
  {
    path: '/pdf',
    name: 'PdfPage',
    components: {
      default: PdfReader,
      header: PdfReaderHeader,
    },
    props: {
      header: true,
      default: route => ({
        ...route.query,
        ...route.params,
      })
    }
  },
  {
    path: '/s/survey/:id',
    name: 'SurveyByIdPage',
    components: {
      default: SurveyById,
    },
    props: {
      default: route => ({
        is_anonymous: route.query.is_anonymous,
        ...route.params,
      })
    },
  },
  {
    path: '/dev_page',
    name: 'DevPage',
    components: {
      default: DevPage,
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router;

// NOT USED

import translationsJSON from "@/translations.json";
import * as Sentry from "@sentry/vue";
import Vue from "vue";

// regex для поиска русских слов [\u0430-\u044f]+
export const translateLang = function(key, lang){
  let translation = translationsJSON[key]?.[lang] || key;
  if (translation === key){
    Sentry.captureException(new Error('No translation for key: '+ key))
  }
  return translation;
}

export const translatePlugin = {
  install(){
    Vue.prototype.$translate = function(key){
      let lang = this.$store.state.layout.language;
      let translation = translationsJSON[key]?.[lang] || key;
      if (translation === key){
        Sentry.captureException(new Error('No translation for key: '+ key))
      }
      return translation;
    }
  },
};
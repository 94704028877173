const state = () => ({
  isInitialized: false,
  avatar: '',
  avatarThumb: '',
  name: 'Имя',
  lastname: 'Фамилия',
  aituMeData: undefined,
  aituPhoneData: undefined,
})

const mutations = {

  'set_name'(state, payload){
    state.name = payload;
  },
  'set_lastname'(state, payload){
    state.lastname = payload;
  },
  'set_avatar'(state, payload){
    state.avatar = payload;
  },
  'set_avatar_thumb'(state, payload){
    state.avatarThumb = payload;
  },
  'set_is_initialized'(state, payload){
    state.isInitialized = payload
  },
  // used for dev purposes
  'set_aitu_me_data'(state, payload){
    state.aituMeData = payload;
  },
  'set_aitu_phone_data'(state, payload){
    state.aituPhoneData = payload;
  }
}

const actions = {
  set({ commit }, { avatar, avatarThumb, name, lastname }){
    commit('set_avatar', avatar);
    commit('set_avatar_thumb', avatarThumb);
    commit('set_name', name);
    commit('set_lastname', lastname);
    commit('set_is_initialized', true);
  },
  // used for dev purposes
  setAituMeData({ commit }, payload){
    commit('set_aitu_me_data', payload);
  },
  // used for dev purposes
  setAituPhoneData({ commit }, payload){
    commit('set_aitu_phone_data', payload);
  },
}

const getters = {

}

export const user = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
<template>
  <v-speed-dial
    v-model="active"
    class="floating-nav"
    :right="true"
    :direction="'top'"
    :transition="'slide-y-reverse-transition'"
  >
    <template v-slot:activator>
      <v-btn
        v-model="active"
        fab
        :ripple="false"
        :disabled="reloadLoading"
        :input-value="false"
        :style="{
          border: 'none',
          boxShadow: '0px 1px 10px',
          color: ('#11111133'),
        }"
      >
        <span
          v-if="!active && unread_total"
          class="floating-nav__notifications-counter notifications-counter--activator"
        >{{ unread_total  }}</span>
        <img
          v-if="reloadLoading"
          src="@/assets/svg/nav-menu_refresh.svg"
        >
        <img
          v-else-if="active"
          src="@/assets/svg/floating-menu_close.svg"
        >
        <img
          v-else
          src="@/assets/svg/floating-menu_burger.svg"
        >
      </v-btn>
    </template>
    <v-btn
      v-for="(item, key) in togglerItems"
      :key="key+'_'+item.title"
      :ripple="false"
      replace
      :to="item.to"
      :exact-active-class="'none'"
      :elevation="0"
      :active-class="'none'"
      fab
      small
      :color="key === activeId ? '#0086E7' : undefined"
      :style="{
        boxShadow: 'none',
      }"
      @click="() => item.hasOwnProperty('action') && item.action()"
    >
      <span
          v-if="(item.title==='Уведомления') && unread_total "
          class="floating-nav__notifications-counter"
        >{{ unread_total }}</span>
      <img
        v-if="key === activeId"
        :src="item.iconActive"
      />
      <img
        v-else
        :src="item.iconDisabled"
      />
    </v-btn>
  </v-speed-dial>

</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { postCustomEvent } from "@/helpers/amplitude";
import { backNavigateQueue } from "@/helpers/app"

export default {
  name: "FloatingNav",
  data(){
    return {
      active: false,
      reloadLoading: false,
      togglerItems: [
        {
          title: "Обновить",
          iconActive: require('@/assets/svg/nav-menu_refresh.svg'),
          iconDisabled: require('@/assets/svg/nav-menu_refresh.svg'),
          action: async () => {
            if (!this.reloadLoading){
              this.reloadLoading = true;
              try {
                await postCustomEvent('Кнопка рестарт', {timeout: 1000});
                localStorage.removeItem('viewed-items')
              } finally {
                location.reload();
              }

            }
          }
        },
        {
          title: "Профиль",
          iconActive: require('@/assets/svg/footer_profile--active.svg'),
          iconDisabled: require('@/assets/svg/footer_profile--disabled.svg'),
          action: () => {
            if (this.show_burger_content){
              this.$store.dispatch('layout/toggleBurgerContent')
            }
            this.$router.replace( {
              name: "ProfilePage",
            })
          }
        },
        {
          title: "Уведомления",
          iconActive: require('@/assets/svg/footer_notification--active.svg'),
          iconDisabled: require('@/assets/svg/footer_notification--disabled.svg'),
          action: () => {
            if (this.show_burger_content) {
              this.$store.dispatch('layout/toggleBurgerContent')
            }
            this.$router.replace({
              name: 'NotificationsPage'
            })
          }
        },
        {
          title: "Главная",
          iconActive: require('@/assets/svg/footer_home--active.svg'),
          iconDisabled: require('@/assets/svg/footer_home--disabled.svg'),
          action: () => {
            if (this.show_burger_content){
              this.$store.dispatch('layout/toggleBurgerContent')
            }
            this.$router.replace({
              name: 'MainPage'
            })
          }
        },
        {
          title: "Burger",
          iconActive: require('@/assets/svg/v2_menu_burger.svg'),
          iconDisabled: require('@/assets/svg/v2_menu_burger.svg'),
          action: () => {
            this.$store.dispatch('layout/toggleBurgerContent')
          },
        },
        {
          title: "Back",
          iconActive: require('@/assets/svg/way_arrow-left.svg'),
          iconDisabled: require('@/assets/svg/way_arrow-left.svg'),
          action: () => {
            backNavigateQueue.exec();
          },
        },
      ]
    }
  },
  computed: {

    activeId(){
      return {
        'MainPage': 3,
        'NotificationsPage': 2,
        'ProfilePage': 1,
      }[this.$route.name]
    },

    ...mapGetters({
      unread_total: 'notifications/unread_total',
    }),
    ...mapState('layout', [ 'show_burger_content']),
  },
}
</script>
<style lang="scss">
.layout{
  .v-speed-dial{
    position: fixed;
    .v-btn{
      border: 1px solid #DDE1E5;
      box-shadow: 0px 1px 10px rgba(102, 122, 139, 0.12);
      &:before{
        opacity: 0 !important;
      }
    }
  }
}
.floating-nav{
  bottom: 128px;
  .floating-nav__item{
    position: relative;
    svg{
      &:focus{
        outline: none;
      }
    }
  }
  .floating-nav__notifications-counter{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    color: #FFFFFF;
    border-radius: 15px;
    min-width: 20px;
    height: 20px;
    left: 20px;
    top: -10px;
    padding: 0 4px;
    margin: 0 auto;
    background: #F5463B;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-align: center;
    &.notifications-counter--activator{
      left: 60%;
    }
  }
}
</style>

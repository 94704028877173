<template>
  <div 
    class="main-sub-menu"
    :class="{
      'main-sub-menu--rounded': rounded,
      'main-sub-menu--rounded-top': roundedTop,
    }"
  >
    <div
      v-for="(i, k) in items" 
      class="main-sub-menu__item"
      :key="k+'_'+i.id"
      @click="() => handleItemClick(i)"
      v-ripple
    >
      <div
        v-if="i.icon" 
        class="item__icon"
      >
        <img :src="i.icon" alt="">
      </div>
      <div class="item__content">
        <p 
          class="small-medium"
          :style="(i.disabled || disabled) && 'color: #DDE1E5;'" 
        >{{ getTranslated('title', i) }}</p>
        <p 
          v-if="getTranslated('subtitle', i)"
          class="caption text-gray-v2"
          :style="(i.disabled || disabled) && 'color: #DDE1E5;'"
        >{{ getTranslated('subtitle', i) }}</p>
      </div>
      <div>
        <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path opacity=".2" d="M1 11l5-5-5-5" stroke="#111114" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex"
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean, 
      default: true,
    },
    roundedTop: {
      type: Boolean, 
      default: false,
    },
    itemsNavigateBackToParent: {
      type: Boolean,
      default: true,
    },
    items: {
      type: Array,
      default(){
        return [];
      }
    },
  },
  computed: {
    ...mapState('layout', ['language']),
    ...mapGetters('mainPage', ['has_children'])
  },
  methods: {
    handleItemClick(i){
      if (i.disabled || this.disabled) return;
      this.$router.replace(this.getRouteToGo(i))
      this.$emit('afterNav');
    },
    getRouteToGo(item) {
      const checkChildren = this.has_children(item.id);

      let routeName = {}

      if (checkChildren) {
        routeName =  {
          name: 'NavigationItemChild',
          params: { 
            menu_id: item.id,
          }
        }
      } else {
        if (this.itemsNavigateBackToParent) {
          switch (item.form_name) {
            case 'erg_way_form':
              routeName = {
                name: 'Way'
              }
              break;

            case 'my_calendar_form':
              routeName = {
                name: 'NavigationItemCalendarFormPage',
                params: { 
                  menu_id: item.id,
                }
              }
              break;

            case 'market':
              routeName = {
                name: 'Marketplace',
              }
              break;

            case 'test_survey_form':
            case 'survey_form':
              routeName = {
                name: 'SurveyByIdPage',
                params: { 
                  id: item.id,
                }
              }
              break;
          
            default:
              routeName = {
                name: 'NavigationItemFormPage',
                params: { 
                  menu_id: item.id,
                  form_name: item.form_name
                }
              }
              break;
          }
        } else {
          switch (item.form_name) {
            case 'erg_way_form':
              routeName = {
                name: 'Way'
              }
              break;

            case 'my_calendar_form':
              routeName = {
                name: 'CalendarFormPage',
              }
              break;

            case 'market':
              routeName = {
                name: 'Marketplace',
              }
              break;
              
            case 'test_survey_form' || 'survey_form':
              routeName = {
                name: 'SurveyByIdPage',
                params: { 
                  id: item.id,
                }
              }
              break;
          
            default:
              routeName = {
                name: 'FormPage',
                params: { 
                  form_name: item.form_name
                }
              }
              break;
          }
        }
      }
      
      return routeName
    },
    getTranslated(section, item){
      return item?.languages?.[this.language]?.[section] || item[section]
    }
  }
}
</script>

<style lang="scss">
.main-sub-menu{
  display: flex;
  flex-direction: column;
  overflow: hidden;
  &--rounded {
    border-radius: 18px;
  }
  &--rounded-top {
    border-radius: 18px 18px 0 0;
  }
  &__item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    // margin: 4px 0;
    padding: 16px;
    background: #FFFFFF;
    // border-radius: 12px;
    &:not(:last-child){
      border-bottom: 1px solid #F9F9F9;
    }
    .item__icon{
      flex: 0 0 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 20px;
      margin-right: 16px;
      img{
        max-width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .item__content{
      flex: 1 1 auto;
      margin: 0 16px 0 0;
    }
    .item__icon-right{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      img{
        max-width: 100%;
      }
    }
  }
}
</style>